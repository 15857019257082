export const passengerManifest = [
    {
        rank: 0,
        code: "vadult",
        label: "Adult",
        labelIE: "Adult",
        price: 2500,
        passengers: 1,
        cars: 0,
        islander: false
    },
    {
        rank: 1,
        code: "vstudent",
        label: "Student",
        labelIE: "Student",
        price: 1500,
        passengers: 1,
        cars: 0,
        islander: false
    },
    {
        rank: 2,
        code: "vchild714",
        label: "Child (7-14)",
        labelIE: "Child (7-14)",
        price: 1000,
        passengers: 1,
        cars: 0,
        islander: false
    },
    {
        rank: 3,
        code: "vchildu7",
        label: "Child (Under 7)",
        labelIE: "Child (Under 7)",
        price: 0,
        passengers: 1,
        cars: 0,
        islander: false
    },
    {
        rank: 4,
        code: "vfamily",
        label: "Family (2 Adults, 2 Children)",
        labelIE: "Family (2 Adults, 2 Children)",
        price: 6000,
        passengers: 4,
        cars: 0,
        islander: false
    },
    {
        rank: 5,
        code: "smartpass",
        label: "Travel / Smart Pass",
        labelIE: "Travel / Smart Pass",
        price: 200,
        passengers: 1,
        cars: 0,
        islander: false
    },
    {
        rank: 6,
        code: "adult",
        label: "Islander Adult",
        labelIE: "Islander Adult",
        price: 1000,
        passengers: 1,
        cars: 0,
        islander: true
    },
    {
        rank: 7,
        code: "student",
        label: "Islander Student",
        labelIE: "Islander Student",
        price: 700,
        passengers: 1,
        cars: 0,
        islander: true
    },
    {
        rank: 8,
        code: "child",
        label: "Islander Child (under 14)",
        labelIE: "Islander Child (under 14)",
        price: 0,
        passengers: 1,
        cars: 0,
        islander: true
    },
    {
        rank: 9,
        code: "family",
        label: "Family (2 Adults, 2 Children)",
        labelIE: "Family (2 Adults, 2 Children)",
        price: 3000,
        passengers: 4,
        cars: 0,
        islander: true
    }
];


export function TicketPassengerTotal(tickets) {
    let totalPassengers = 0;
    tickets.forEach((t) => {
        var passengers = 0;
        passengers += t.passengers.map((p) => p.passengers * p.quantity).reduce((total, inc) => (total += inc), 0);
        totalPassengers += passengers;
    });
    return totalPassengers;
}

export function SingleTicketPassengerTotal(ticket) {
    let passengers = 0;
    passengers += ticket.passengers.map((p) => p.passengers * p.quantity).reduce((total, inc) => (total += inc), 0);
    return passengers;
}

export function getEmptyPassengerList() {
    let passengerList = {};

    passengerManifest.forEach((pm) => {
        passengerList[pm.code] = 0;
    });

    // console.table(passengerList);
    return passengerList;
}
