import React, { useState } from "react";
import { format, isAfter, isSameDay } from "date-fns";

import { Space, Tag, Typography, Drawer, Button, Card, Col, Row } from "antd";
import * as TimeUtils from "../../../utils/TimeUtils";
import FerryTypeLabel from "../FerryTypeLabel";
import { ArrowRightOutlined, CarOutlined, LockOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import * as GlobalUtils from "../../../utils/Global";
import { FaShuttleVan } from "react-icons/fa";
import { TbBike, TbTruck } from "react-icons/tb";

export default function V2MobileBottomSheet(props) {
    // const { times, tickets, setFerry, date, blocks, avails } = props;
    const { date, timeKeyData, dateTimeKey, setDateTimeKey } = props;

    const [open, setOpen] = useState(false);

    const nowTime = isSameDay(new Date(), date) || isAfter(new Date(), date) ? parseInt(format(new Date(), "HHmm")) : 0;

    return (
        <Row>
            <Button onClick={() => setOpen(true)} block>
                Change Time
            </Button>
            <Drawer
                title={`Times for ${format(date, "do MMM yyyy")}`}
                placement="bottom"
                onClose={() => setOpen(false)}
                open={open}
                closable={true}
                height={"75%"}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    {timeKeyData.map((time, index) => (
                        <Card
                            key={index}
                            size="small"
                            style={{
                                cursor: "pointer",
                                borderLeftColor: nowTime > time.time ? "#d9d9d9" : time.journey === "dt" ? "#91D5FF" : "#FFBB96",
                                borderLeftWidth: "6px"
                            }}
                            onClick={() => {
                                setDateTimeKey(time);
                                setOpen(false);
                            }}
                        >
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Row justify="space-between">
                                    <Col>
                                        <Typography.Text>{TimeUtils.getPrettyTimeAMPM(time.time)}</Typography.Text>
                                    </Col>
                                    <Col align={"end"}>
                                        <FerryTypeLabel
                                            locked={time.type === "locked" ? true : false}
                                            type={time.type}
                                            hideScheduled={true}
                                        />
                                        {dateTimeKey !== null && dateTimeKey.time === time.time ? (
                                            <SyncOutlined style={{ color: "#BDC3C7" }} />
                                        ) : (
                                            <ArrowRightOutlined style={{ color: "#BDC3C7" }} />
                                        )}
                                    </Col>
                                </Row>
                                <Space direction="horizontal" wrap>
                                    <Tag
                                        size="large"
                                        color={time.passengers >= GlobalUtils.totalPassengers ? "red" : "blue"}
                                        icon={
                                            <UserOutlined
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            />
                                        }
                                    >
                                        <Typography.Text style={{ fontSize: "1.2em", color: "inherit" }}>{time.passengers}</Typography.Text>
                                    </Tag>

                                    {time.blocks.length > 0 ? (
                                        <Tag size="large" color={"orange"} icon={<LockOutlined />}>
                                            <Typography.Text style={{ fontSize: "1.2em", color: "inherit" }}>
                                                {time.blocks.length}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {GlobalUtils.deckSpaces ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaces + time.blocks.length * 2 >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                            icon={
                                                <CarOutlined
                                                    style={{
                                                        fontSize: "1.2em",
                                                        color: "inherit"
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            >
                                                {time.cars}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {time.vans > 0 ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaces + time.blocks.length * 2 >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                        >
                                            <FaShuttleVan
                                                style={{
                                                    fontSize: "1.2em",
                                                    paddingTop: "4px",
                                                    paddingBottom: "0px",
                                                    color: "inherit"
                                                }}
                                            />
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit",
                                                    paddingLeft: "2px"
                                                }}
                                            >
                                                {time.vans}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {time.trucks > 0 ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaceTotal >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                            icon={
                                                <TbTruck
                                                    style={{
                                                        fontSize: "1.5em",
                                                        paddingTop: "2px",
                                                        paddingBottom: "2px",
                                                        color: "inherit"
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            >
                                                {time.trucks}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {time.bikes > 0 ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaces + time.blocks.length * 2 >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                            icon={
                                                <TbBike
                                                    style={{
                                                        fontSize: "1.5em",
                                                        paddingTop: "2px",
                                                        paddingBottom: "2px",
                                                        color: "inherit"
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            >
                                                {time.bikes}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                </Space>
                            </Space>
                        </Card>
                    ))}
                </Space>
            </Drawer>
        </Row>
    );
}
