import React, { useState, useEffect } from "react";

import { Divider, PageHeader, Button, Row, Col, Popconfirm, Card, Table, DatePicker, message, Spin, Space, Typography } from "antd";
import LogoutButton from "../components/LogoutButton";

import moment from "moment";

import { UpCircleOutlined, UpSquareOutlined, DownSquareOutlined } from "@ant-design/icons";

import * as ToryService from "../services/ToryService";
import * as Constants from "../utils/Constants";
import { JourneyPicker } from "../components/common/JourneyPicker";
import CancelButtons from "../components/cancel/CancelButtons";
import { CancelMovePanel } from "../components/cancel/CancelMovePanel";

function Cancel() {
    const [journey, setJourney] = useState("dm");
    const [chosenDate, setChosenDate] = useState(new Date());
    const [ferries, setFerries] = useState(0);
    const [times, setTimes] = useState([]);
    const [conflictTickets, setConflictTickets] = useState([]);
    const [confirmTime, setConfirmTime] = useState(null);
    const [showArchive, setShowArchive] = useState(false);
    const [archiveFerries, setArchiveFerries] = useState({ dt: [], dm: [] });

    function resetForm() {
        setJourney("dm");
        setChosenDate(new Date());
        // setFerries({ dt: [], dm: [] });
        setTimes([]);
        setConflictTickets([]);
        setConfirmTime(null);
    }

    async function loadTables() {
        let response = await ToryService.getCancel();
        // console.log(response);
        setFerries(response);
    }

    async function toggleArchive() {
        // DO POST
        let archiveFerries = await ToryService.getCancelArchive();

        setShowArchive(!showArchive);
        setArchiveFerries(archiveFerries);
    }

    async function cancelFerry(time) {
        if (chosenDate === 0) {
            message.info("No chosen date selected");
            return;
        }
        if (time === null) {
            message.info("No time selected");
            return;
        }
        // console.log(time);
        // console.log(journey);
        // console.log(chosenDate);
        let result = await ToryService.cancelFerry(journey, chosenDate, time);
        // console.log(result);
        if (result.status === 200) {
            message.success("Ferry Cancelled");
            resetForm();
            loadTables();
        } else if (result.status === "CONFLICTS") {
            message.info("Please confirm");
            // CONFLICTS
            // CONFIRM TIME
            setConfirmTime(time);
            setConflictTickets(result.tickets);
        } else {
            message.error("Couldn't Cancel Ferry");
        }
    }

    async function confirmCancelFerry() {
        // console.log(time);
        // console.log(journey);
        const epochDate = chosenDate.getTime();
        // console.log(chosenDate);
        let result = await ToryService.confirmCancelFerry(journey, epochDate, confirmTime);
        // console.log(result);
        if (result.status === 200) {
            message.success("Ferry Cancelled");
            resetForm();
            loadTables();
        } else {
            message.error("Couldn't Cancel Ferry");
        }
    }

    async function addCancelledFerry(newCancellation) {
        let dtFerries = ferries.dt;
        let dmFerries = ferries.dm;
        if (newCancellation.journey === "dt") {
            dtFerries.push(newCancellation);
        } else {
            dmFerries.push(newCancellation);
        }
        setFerries({
            dt: dtFerries,
            dm: dmFerries
        });
        resetForm();
    }

    async function undelete(id) {
        console.log(id);
        let result = await ToryService.unCancelFerry(id);
        if (result === 200) {
            message.success("Ferry Uncancelled");
            resetForm();
            loadTables();
        } else {
            message.error("Couldn't uncancel Ferry");
        }
    }

    useEffect(() => {
        // LOAD TABLES
        loadTables();
        // console.log("[] effect run");
    }, []);

    useEffect(() => {
        // console.log("useEffect only for time");
        loadTimes();
        setConflictTickets([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenDate, journey]);

    function changeJourney(journey) {
        setJourney(journey);
        // loadTimes()
    }
    function changeDate(date, dateString) {
        if (date !== null) {
            // console.log("non null date");
            // console.log(Constants.getEpochDate(date._d));
            setChosenDate(new Date(Constants.getEpochDate(date._d)));
            // loadTimes();
        }
    }

    async function loadTimes() {
        // console.log(chosenDate)
        if (chosenDate !== 0) {
            // console.log(journey);
            // console.log(chosenDate)
            let result = await ToryService.getDateTimes(journey, chosenDate);
            // console.log(result);
            setTimes(Constants.getOneDayTimesArray(result));
        } else {
            setTimes([]);
        }
    }

    // console.log(times)

    return (
        <>
            <PageHeader ghost={false} title="Cancel" extra={<LogoutButton />}></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col className="gutter-row" xs={24} md={12}>
                        <Card title="Cancel Ferry" bordered={false}>
                            <Space direction="vertical">
                                <Typography.Title level={5}>Journey</Typography.Title>

                                <JourneyPicker journey={journey} onJourneyChange={(e) => changeJourney(e.target.value)} />

                                <Typography.Title level={5}>Date</Typography.Title>
                                <div className={"pb-1"}>
                                    <DatePicker value={new moment(chosenDate)} onChange={changeDate} />
                                </div>
                                <Typography.Title level={5}>Times</Typography.Title>
                                {times.length === 0 ? (
                                    <Typography.Text>No Times Available</Typography.Text>
                                ) : (
                                    <CancelButtons cancelFerry={cancelFerry} changeType={"cancel"} ferries={times} />
                                )}
                                {confirmTime ? (
                                    <Space direction="vertical">
                                        <Divider />
                                        <Typography.Title level={5}>Confirm Cancellation</Typography.Title>
                                        <Row>
                                            <Typography.Text>
                                                {`This cancellation conflicts with ${conflictTickets.length} tickets. Please confirm to
                        confirm cancellation and send email and notification OR move tickets to another ferry and send email.`}
                                            </Typography.Text>
                                        </Row>
                                        <Space>
                                            <Button type="primary" danger onClick={confirmCancelFerry}>
                                                Confirm Cancellation
                                            </Button>
                                            <CancelMovePanel
                                                confirmedMove={(v) => addCancelledFerry(v)}
                                                cancelJourney={journey}
                                                cancelDate={chosenDate}
                                                cancelTime={confirmTime}
                                                cancelTickets={conflictTickets}
                                            />
                                        </Space>
                                    </Space>
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12}>
                        <Card title="Cancelled Ferries" bordered={false}>
                            {ferries === 0 ? (
                                <Row justify="center">
                                    <Spin />
                                </Row>
                            ) : (
                                <CancelTables ferries={ferries} undelete={undelete} />
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>

            <div style={{ margin: "24px 16px 0" }}>
                <Divider orientation="left">
                    <Button
                        icon={showArchive ? <UpSquareOutlined /> : <DownSquareOutlined />}
                        onClick={toggleArchive}
                        type="outline"
                        color="secondary"
                    >
                        {showArchive ? "Hide" : "Show"} Archive{" "}
                    </Button>
                </Divider>
                {showArchive ? <ArchiveTable archiveFerries={archiveFerries} /> : <></>}
            </div>
        </>
    );
}

export default Cancel;

const CancelTables = (props) => {
    return (
        <>
            <CancelTable journey="dm" ferries={props.ferries.dm} undelete={props.undelete} />
            <br />
            <CancelTable journey="dt" ferries={props.ferries.dt} undelete={props.undelete} />
        </>
    );
};

const CancelTable = (props) => {
    // console.log(props.ferries);

    let data = [];

    props.ferries.map((i, index) => {
        let j = i;
        j.key = i.id;
        return data.push(j);
    });

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => <span>{Constants.getDateString(value)}</span>
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            render: (value) => <span>{Constants.getTimestamp(value)}</span>
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (value) => (
                <>
                    <Popconfirm
                        title="Are you sure UN-cancel this ferry?"
                        okText="Yes"
                        onConfirm={() => props.undelete(value)}
                        onCancel={Constants.cancel}
                        cancelText="No"
                    >
                        <Button size="small" danger>
                            Uncancel <UpCircleOutlined twoToneColor="#ff4d4f" />
                        </Button>
                    </Popconfirm>
                </>
            )
        }
    ];

    const journey = Constants.getJourneyString(props.journey);

    return (
        <>
            <h4>{journey}</h4>
            <Table size="small" dataSource={data} columns={columns} pagination={false} />
        </>
    );
};

const ArchiveTable = (props) => {
    let archiveData = [];

    props.archiveFerries.dm.map((i, index) => {
        let j = i;
        j.key = i.id;
        j.journey = "dm";
        return archiveData.push(j);
    });
    props.archiveFerries.dt.map((i, index) => {
        let j = i;
        j.journey = "dt";
        j.key = i.id;
        return archiveData.push(j);
    });

    archiveData.sort((a, b) => {
        return a.time - b.time;
    });

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (value) => <span>{Constants.getIDString(value).toUpperCase()}</span>
        },
        {
            title: "Journey",
            dataIndex: "journey",
            key: "journey",
            render: (value) => <span>{Constants.getJourneyString(value)}</span>
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => <span>{Constants.getDateString(value)}</span>
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            render: (value) => <span>{Constants.getTimestamp(value)}</span>
        }
    ];

    return (
        <>
            <Table dataSource={archiveData} columns={columns} pagination={true} />
        </>
    );
};
