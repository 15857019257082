import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import "./css/Sidebar.css";

import { Layout, Menu, Row } from "antd";
import {
    DashboardOutlined,
    CalendarOutlined,
    CloseCircleOutlined,
    PlusCircleOutlined,
    CalendarFilled,
    UserOutlined,
    LockOutlined,
    QrcodeOutlined,
    BellOutlined,
    StarOutlined,
    ExclamationCircleOutlined,
    MessageOutlined,
    EuroOutlined,
    SettingOutlined
} from "@ant-design/icons";

const { Sider } = Layout;

const Sidebar = (props) => {
    const { history } = props;

    const [sidebarCollapsed, setSidebarCollapsed] = useState(window.innerWidth > 900 ? false : true);

    const menu = [
        {
            label: "Dashboard",
            location: "/",
            route: () => history.push("/"),
            icon: <DashboardOutlined />
        },
        {
            label: "Master Timetables",
            location: "/timetables",
            route: () => history.push("/timetables"),
            icon: <CalendarOutlined />
        },
        {
            label: "Cancel Ferries",
            location: "/cancel",
            route: () => history.push("/cancel"),
            icon: <CloseCircleOutlined />
        },
        {
            label: "Extra Ferries",
            location: "/extra",
            route: () => history.push("/extra"),
            icon: <PlusCircleOutlined />
        },
        {
            label: "Reroute Ferries",
            location: "/reroute",
            route: () => history.push("/reroute"),
            icon: <ExclamationCircleOutlined />
        },
        {
            label: "Lock Ferries",
            location: "/lock",
            route: () => history.push("/lock"),
            icon: <LockOutlined />
        },
        {
            label: "Tickets",
            location: "/tickets",
            route: () => history.push("/tickets"),
            icon: <QrcodeOutlined />
        },
        {
            label: "Accounts",
            location: "/accounts",
            route: () => history.push("/accounts"),
            icon: <UserOutlined />
        },
        {
            label: "Notifications",
            location: "/notifications",
            route: () => history.push("/notifications"),
            icon: <BellOutlined />
        },
        {
            label: "Messages",
            location: "/messages",
            route: () => history.push("/messages"),
            icon: <MessageOutlined />
        },
        {
            label: "Definitions",
            location: "/definitions",
            route: () => history.push("/definitions"),
            icon: <CalendarFilled />
        },

        {
            label: "Prices",
            location: "/prices",
            route: () => history.push("/prices"),
            icon: <EuroOutlined />
        },
        {
            label: "Golden Tickets",
            location: "/golden-tickets",
            route: () => history.push("/golden-tickets"),
            icon: <StarOutlined />
        },
        {
            label: "Settings",
            location: "/settings",
            route: () => history.push("/settings"),
            icon: <SettingOutlined />
        }
    ];

    function getMenuHighlight(locationString) {
        // console.log(locationString)
        // return menu.indexOf(locationString);
        let num = 0;
        menu.forEach((m, index) => {
            if (m.location === locationString) {
                num = index;
            }
        });
        return num;
    }

    return (
        <SwitchedSider sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} width={window.innerWidth}>
            <Row justify="center">
                <div className="logo" />
            </Row>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["0"]}
                selectedKeys={[getMenuHighlight(props.location.pathname).toString()]}
            >
                {menu.map((item, index) => {
                    const { label, icon, route } = item;
                    return (
                        <Menu.Item key={index} icon={icon} onClick={route}>
                            {label}
                        </Menu.Item>
                    );
                })}
            </Menu>
        </SwitchedSider>
    );
};

export default withRouter(Sidebar);

function SwitchedSider(props) {
    const { width, sidebarCollapsed, setSidebarCollapsed, children } = props;

    return width > 900 ? (
        <Sider breakpoint="lg" collapsible collapsed={sidebarCollapsed} onCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}>
            {children}
        </Sider>
    ) : (
        <Sider
            breakpoint="lg"
            collapsible
            collapsedWidth={0}
            collapsed={sidebarCollapsed}
            onCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
        >
            {children}
        </Sider>
    );
}
