import React, {
	useState
} from 'react';

import {
	Layout,
	Row,
	Col,
	Card,
	Button,
	Spin,
	Typography,
	Input,
	Form,
	Space
} from 'antd';

import {
	MailOutlined
} from '@ant-design/icons';

import {
	GoogleOutlined,
} from '@ant-design/icons';
import { signInWithEmail, signInWithGoogle } from '../services/firebase';

const { Content } = Layout;

function SignIn() {

	const [isLoading, setIsLoading] = useState(false);
	const [username, setUsername] = useState("toryferry@hexastudios.co");
	const [password, setPassword] = useState("")
	// console.log(localStorage.getItem("loggedIn"));

	function login() {
		setIsLoading(true);
		signInWithGoogle()
	}

	function cancelLogin() {
		localStorage.setItem("loggedIn", "false");
	}

	return (
		localStorage.getItem("loggedIn") === "true" ? <Row justify="center" >
			<Col xs={18} md={8} style={{ textAlign: 'center' }}>
				<div style={{ textAlign: 'center', paddingBottom: "30px", paddingTop: "80px" }}>
					<img
						alt="Tory Ferry Logo"
						src="512.png"
						style={{ width: "100px" }} />
				</div>

				<div style={{ textAlign: 'center', paddingTop: "10px" }}>
					<Spin />
					<br />
					<Button type="link" onClick={cancelLogin}>Cancel</Button>
				</div>

			</Col>
		</Row> :
			<Layout id={"vanta-bg"} style={{
				minHeight: "100vh",
				backgroundColor: "#f0f0f0"
			}}>
				<Content>
					<Row justify="center" >
						<Col xs={18} md={8} style={{ textAlign: 'center' }}>
							<div style={{ textAlign: 'center', paddingBottom: "30px", paddingTop: "80px" }}>
								<img
									alt="Tory Ferry Logo"
									src="512.png"
									style={{ width: "100px" }} />
							</div>
							<Card
								actions={[
									// <Button onClick={() => signInWithGoogle()} type="outlined" icon={<MailOutlined />}>Email Sign in</Button>,
									isLoading ? <Spin /> :
										<Space direction="horizontal">
											<Button icon={<MailOutlined />} onClick={() => signInWithEmail(username, password)}>
												Sign In
											</Button>
											<Button onClick={() => login()} type="primary" icon={<GoogleOutlined />}>Google Sign in</Button>
										</Space>
								]}>
								<Typography.Title level={4}>Tory Ferry Admin</Typography.Title>

								<Typography.Text>Log In</Typography.Text>

								<Form layout="vertical">
									<Form.Item label="Email">
										<Input placeholder="toryferry@hexastudios.co" onChange={(v) => setUsername(v.target.value)} />
									</Form.Item>
									<Form.Item label="Password">
										<Input placeholder="password" type="password" onChange={(v) => setPassword(v.target.value)} />
									</Form.Item>
								</Form>

							</Card>

						</Col>
					</Row>
					<Row justify="center" >
						<Col xs={18} md={8} style={{ textAlign: 'center' }}>

							<div style={{ textAlign: 'center', paddingTop: "20px", paddingBottom: "10px", color: "#8c8c8c" }}>Tory Ferry © {`${new Date().getFullYear()}`}</div>

							{/* <Button onClick={signOutWithGoogle} type="primary" >Sign Out</Button> */}
						</Col>
					</Row>
				</Content>
			</Layout>
	);
}

export default SignIn