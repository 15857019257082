import { Typography, Space, Button, Spin, Divider, Table, Row, Col, Tag, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { passengerManifest } from "../../../utils/PassengerUtils";
import * as PriceService from "../../../services/PriceService";
import { UserOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { maxDeckSpacesAvailable } from "../../../utils/Constants";
import { PassengerList } from "./PassengerList";
import { AddCustomPrice } from "./AddCustomPrice";

export function PassengerListBuilder(props) {
    const { newTicket, updatePassengers, removePassenger } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [priceList, setPriceList] = useState([]);

    useEffect(() => {
        getPrices();
    }, []);

    async function getPrices() {
        setIsLoading(true);
        let response = await PriceService.getPrices();
        // console.log(response);
        setPriceList(response);
        setIsLoading(false);
    }

    return (
        <Space style={{ width: "100%" }}>
            {isLoading ? (
                <Spin />
            ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {/* ISLANDERS */}
                    <Typography.Text style={{ fontWeight: "bold" }}>Islanders</Typography.Text>
                    <Space size={[16, 16]} wrap>
                        {priceList
                            .filter((p) => p.islander == true)
                            .map((price) => (
                                <Button
                                    key={price._id}
                                    size="medium"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() =>
                                        updatePassengers({
                                            code: price.code,
                                            label: price.label,
                                            price: price.price,
                                            quantity: 1,
                                            passengers: price.passengers,
                                            deckSpaces: price.deckSpaces,
                                            islander: price.islander,
                                            // key: new Date().getTime()
                                        })
                                    }
                                >{`${price.label}`}</Button>
                            ))}
                    </Space>
                    {/* VISITORS */}
                    <Typography.Text style={{ fontWeight: "bold", paddingtop: "1em" }}>Visitors</Typography.Text>
                    <Space size={[16, 16]} wrap>
                        {priceList
                            .filter((p) => p.islander == false)
                            .map((price) => (
                                <Button
                                    key={price._id}
                                    size="medium"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() =>
                                        updatePassengers({
                                            code: price.code,
                                            label: price.label,
                                            price: price.price,
                                            quantity: 1,
                                            passengers: price.passengers,
                                            deckSpaces: price.deckSpaces,
                                            islander: price.islander,
                                            // key: new Date().getTime()
                                        })
                                    }
                                >{`${price.label}`}</Button>
                            ))} 
                    </Space>
                    <Typography.Text style={{ fontWeight: "bold", paddingtop: "1em" }}>Custom Price</Typography.Text>
                    <AddCustomPrice updateCustomPrice={updatePassengers} /> 
                    <PassengerList newTicket={newTicket} removePassenger={removePassenger} showRemove={true} showTotals={true} />
                </Space>
            )}
        </Space>
    );
}

//  "code": "vstudent",
// "label": "Student",
// "price": 1500,
// "quantity": 1,
// "passengers": 1,
// "deckSpaces": 0
//

// export function PassengerList(props) {
//     const { newTicket, removePassenger, showRemove } = props;
//     const { passengers } = newTicket;

//     return passengers.length == 0 ? (
//         <Space
//             direction="vertical"
//             style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
//         >
//             <Typography.Text style={{ fontWeight: "bold" }}>
//                 No Passengers Selected For Ticket
//             </Typography.Text>
//         </Space>
//     ) : (
//         <Space
//             style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}
//             direction="vertical"
//         >
//             {/* <Typography.Text>{`${JSON.stringify(passengers)}`}</Typography.Text> */}

//             <Space direction="vertical" style={{ width: "100%" }}>
//                 <Row justify="space-between" style={{ width: "100%" }}>
//                     <Col>
//                         <Typography.Text style={{ fontWeight: "bold" }}>
//                             {`Passengers: ${passengers
//                                 .map((p) => p.quantity * p.passengers)
//                                 .reduce(
//                                     (accumulator, currentValue) =>
//                                         accumulator + currentValue,
//                                     0
//                                 )}`}
//                             {maxDeckSpacesAvailable > 0
//                                 ? `Cars: ${passengers
//                                       .map((p) => p.quantity * p.deckSpaces)
//                                       .reduce(
//                                           (accumulator, currentValue) =>
//                                               accumulator + currentValue,
//                                           0
//                                       )}`
//                                 : ""}
//                         </Typography.Text>
//                     </Col>
//                     <Col>
//                         <Typography.Text style={{ fontWeight: "bold" }}>
//                             {`Total: € ${(
//                                 passengers
//                                     .map((p) => p.quantity * p.price)
//                                     .reduce(
//                                         (accumulator, currentValue) =>
//                                             accumulator + currentValue,
//                                         0
//                                     ) / 100
//                             ).toFixed(2)}`}
//                         </Typography.Text>
//                     </Col>
//                 </Row>
//                 <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.06)" }} />
//             </Space>
//             <Space style={{ width: "100%" }} direction="vertical">
//                 {passengers.map((p) => (
//                     <Space
//                         key={p.code}
//                         direction="vertical"
//                         style={{ width: "100%" }}
//                     >
//                         <Row justify="space-between" style={{ width: "100%" }}>
//                             <Col span={14}>
//                                 <Typography.Text
//                                     key={p.code}
//                                 >{`${p.quantity}x ${p.label}`}</Typography.Text>
//                                 {p.islander ? (
//                                     <Tag
//                                         size="small"
//                                         style={{ marginLeft: "0.5em" }}
//                                         color="green"
//                                     >
//                                         Islander
//                                     </Tag>
//                                 ) : (
//                                     <Tag
//                                         size="small"
//                                         style={{ marginLeft: "0.5em" }}
//                                         color="blue"
//                                     >
//                                         Visitor
//                                     </Tag>
//                                 )}
//                             </Col>
//                             <Col span={showRemove ? 6 : 10}>
//                                 <Space direction="horizontal" wrap>
//                                     {[
//                                         ...Array(
//                                             p.quantity * p.passengers
//                                         ).keys()
//                                     ].map((p) => (
//                                         <UserOutlined key={p} />
//                                     ))}
//                                 </Space>
//                             </Col>
//                             {showRemove ? (
//                                 <Col span={4} justify="right">
//                                     <Button
//                                         onClick={() => removePassenger(p.code)}
//                                         key={p.code}
//                                         size="small"
//                                         danger
//                                     >{`Remove`}</Button>
//                                 </Col>
//                             ) : (
//                                 <></>
//                             )}
//                         </Row>
//                         <hr
//                             style={{
//                                 borderTop: "1px solid rgba(0, 0, 0, 0.06)"
//                             }}
//                         />
//                     </Space>
//                 ))}
//             </Space>
//         </Space>
//     );
// }
