import React from "react";

import {
    Button,
    Popconfirm,
    Space,
    Tooltip,
    // Typography,
} from "antd";

import {
    CloseCircleOutlined,
} from "@ant-design/icons";

import * as Constants from "../../utils/Constants";

export default function CancelButtons(props) {

    const { ferries, changeType, cancelFerry } = props;

    return (
        <Space size="middle" wrap>
            {ferries.sort((a, b) => a.time - b.time).map((i, index) => {
                return (
                    <Popconfirm
                        key={index}
                        title={`Are you sure ${changeType} this ferry?`}
                        okText="Yes"
                        onConfirm={() => cancelFerry(i.time)}
                        onCancel={Constants.cancel}
                        cancelText="No"
                    >
                        {i.ferry !== "master" ? (
                            <Tooltip title={`Note: This is a ${i.ferry} ferry, but you can still ${changeType} it`}>
                                <Button icon={<CloseCircleOutlined />} style={{}}>
                                    {/* <Typography.Text> */}
                                        {` ${Constants.getTimestamp(i.time)} `}
                                    {/* </Typography.Text> */}
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button danger icon={<CloseCircleOutlined />} style={{}}>
                                {/* <Typography.Text> */}
                                    {` ${Constants.getTimestamp(i.time)} `}
                                {/* </Typography.Text> */}
                            </Button>
                        )}
                    </Popconfirm>
                );
            })}
        </Space>
    );
};
