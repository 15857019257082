import React from 'react';

import {
    Col,
    Drawer,
    Spin,
} from 'antd';


import {
    CloseSquareOutlined,
} from '@ant-design/icons';

import TicketViewer from './TicketViewer';

const ViewPanel = (props) => {

    const {
        width,
        onClose,
        visible,
        singleTicket
    } = props;

    return (
        <Drawer
            title="View Ticket"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width}
            onClose={onClose}
            open={visible} >

            {singleTicket
                ? <TicketViewer singleTicket={singleTicket} />
                : <Col xs={18} md={8} style={{ textAlign: 'center' }}>

                    <div style={{ textAlign: 'center', paddingTop: "10px" }}>
                        <Spin />
                    </div>

                </Col>

            }

        </Drawer >
    );

};
export default ViewPanel;
