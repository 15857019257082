import React, {
	useState,
} from 'react';

import {
	Divider,
	PageHeader,
	Button,
	Row,
	Col,
	Input,
	Space,
	Card,
	Table,
	Radio,
	Typography,
	message
} from 'antd';

import {
	UpSquareOutlined,
	DownSquareOutlined,
	SaveOutlined
} from '@ant-design/icons';

import * as Constants from '../utils/Constants';
import * as ToryService from '../services/ToryService';
import LogoutButton from '../components/LogoutButton';
import { CardRightAlignedAction } from '../components/common/CardRightAlignedAction';

function Notifications() {

	// SETTING NEW ONES
	const [notificationType, setNotificationType] = useState("news");
	const [msg, setMessage] = useState("");
	const [title, setTitle] = useState("");
	// ARCHIVE
	const [showArchive, setShowArchive] = useState(false);
	const [archiveFerries, setArchiveFerries] = useState([]);
	// const [showAdd, setAdd] = useState(false);

	function resetForm() {
		setNotificationType("");
		setMessage("");
		setTitle("");
	}

	async function toggleArchive() {
		// console.log(msg);
		// DO POST
		let archiveFerries = await ToryService.getNotificationArchive();
		// console.log(archiveFerries);
		setShowArchive(!showArchive);
		setArchiveFerries(archiveFerries);
	}

	async function notificationAdd() {
		// console.log(title);
		// console.log(msg);
		// console.log(notificationType);
		let result = await ToryService.addNotification(title, msg, notificationType);
		// console.log(result);
		if (result === 200) {
			message.success('Notification Send');
			resetForm()
		} else {
			message.error("Couldn't Send Notification");
		}
	}

	return (<>
		<PageHeader
			ghost={false}
			title="Notifications"
			extra={
				<LogoutButton />
			}>
		</PageHeader>

		<div style={{ margin: '8px 16px 0' }}>
			<Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
				<Col className="gutter-row" xs={24} md={12}>
					<Card title="Send Notification" bordered={false} actions={[
						<CardRightAlignedAction>
							<Button onClick={notificationAdd} disabled={!(msg !== "" && title !== "")} icon={<SaveOutlined />} type="primary">Add</Button>
						</CardRightAlignedAction>
					]}>

						<Space direction="vertical" size="middle" style={{ width: "100%" }}>

							<Typography.Title level={5}>Notification Type</Typography.Title>
							<Radio.Group defaultValue="news" onChange={(e) => setNotificationType(e.target.value)} className={"pb-1"} buttonStyle="solid">
								<Radio.Button value="news">News</Radio.Button>
								<Radio.Button value="changes">Changes</Radio.Button>
								{process.env.NODE_ENV === "development" 
								? <Radio.Button value="dev">Dev</Radio.Button> 
								: <></>}
							</Radio.Group>
							<Typography.Title level={5}>Notification Title</Typography.Title>
							<div className={"pb-1"}>
								<Input value={title} onChange={(v) => setTitle(v.target.value)} />
							</div>
							<Typography.Title level={5}>Notification Message</Typography.Title>
							<div className={"pb-1"}>
								<Input.TextArea value={msg} onChange={(v) => setMessage(v.target.value)} />
							</div>

						</Space>

					</Card>
				</Col>
			</Row>
		</div>

		<div style={{ margin: '24px 16px 0' }}>
			<Divider orientation="left">
				<Button
					icon={showArchive ? <UpSquareOutlined /> : <DownSquareOutlined />}
					onClick={toggleArchive} type="outline" color="secondary">
					{showArchive ? "Hide" : "Show"} Archive </Button>
			</Divider>
			{showArchive ? <ArchiveTable archiveFerries={archiveFerries} /> : <></>}
		</div>

	</>);
}

export default Notifications;

const ArchiveTable = (props) => {

	// console.log(props.archiveFerries);

	let archiveData = []

	props.archiveFerries.map((i) => {
		let j = i;
		j.key = i._id
		return archiveData.push(j);
	});

	// console.log(archiveData);

	archiveData.sort((a, b) => {
		return a.createdOn - b.createdOn;
	});

	const columns = [
		{
			title: 'ID',
			dataIndex: '_id',
			key: '_id',
			render: (value) => (
				<span>{Constants.getIDString(value).toUpperCase()}</span>
			),
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Notification',
			dataIndex: 'message',
			key: 'message',
		},
		{
			title: 'Sent',
			dataIndex: 'createdOn',
			key: 'createdOn',
			render: (value) => (
				<span>{Constants.getDateTimeString(value)}</span>
			),
		},
	];

	return (<>
		<Table dataSource={archiveData} columns={columns} pagination={false} />
	</>)
}