
export function getMonthlyData(months, ferries) {

	let times = [];

	// console.log(ferries);

	ferries.forEach(element => {
		// console.log(element);
		if (element.months.some(r => months.includes(r))) {
			times.push(element);
		}
	});

	// console.log(times);
	return times;

}

export function getDailyData(journey, days, ferries) {

	let times = [];

	ferries.forEach(element => {
		if (element.days.some(r => days.includes(r))) {
			if (element.journey === journey) {
				times.push(element);
			}
		}
	});

	// console.log(times);
	times.sort((a, b) => {
		return a.time - b.time;
	});
	// times.sort();
	return times;

}