import React, { useState, useEffect } from "react";
import { Divider, PageHeader, Button, Modal, Row, Col, message, Space } from "antd";
import LogoutButton from "../components/LogoutButton";
import * as GoldenTicketService from "../services/GoldenTicketService";
import { NewGoldenTicketPanel } from "../components/goldentickets/NewGoldenTicketPanel";
import { PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { GoldenTicketTable } from "../components/goldentickets/GoldenTicketTable";
import { Segmented } from "antd";
//
export default function GoldenTickets() {
    const [goldenTickets, setGoldenTickets] = useState([]);
    const [getRedeemed, setRedeemed] = useState("Available");

    // DRAWER
    const [openDrawer, setOpenDrawer] = useState(false);
    //
    function onClose() {
        setOpenDrawer(false);
        loadGoldenTickets();
    }

    useEffect(() => {
        loadGoldenTickets();
    }, []);

    useEffect(() => {
        loadGoldenTickets();
    }, [getRedeemed]);

    async function loadGoldenTickets() {
        if (getRedeemed === "Redeemed") {
            let { data, error } = await GoldenTicketService.getGoldenRedeemedTickets();
            // console.log(data, error);
            if (error === 0) {
                setGoldenTickets(data);
            } else {
                message.error("Could not get golden ticket");
            }
        } else {
            let { data, error } = await GoldenTicketService.getGoldenTickets();
            // console.log(data, error);
            if (error === 0) {
                setGoldenTickets(data);
            } else {
                message.error("Could not get golden ticket");
            }
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <PageHeader ghost={false} title="Golden Tickets" extra={<LogoutButton />}></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Space style={{ maxWidth: "100%", width: "100%" }} direction="vertical" size="small">
                        <Row>
                            <Col span={24} flex={"auto"}>
                                <Divider orientation="right">
                                    <Space direction="horizontal">
                                        <Button type="default" onClick={showModal}>
                                            How It Works
                                        </Button>
                                        <Modal
                                            title="How It Works"
                                            open={isModalOpen}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            footer={[
                                                <Button key="back" onClick={handleCancel}>
                                                    Back
                                                </Button>
                                            ]}
                                        >
                                            <p>We add a date and price limit for the Golden Ticket</p>
                                            <p>
                                                The first user who uses the app and buys a ticket after the "Golden Ticket Time" gets their
                                                ticket for free, as long as they are under the price limit.
                                            </p>
                                            <p>
                                                We can add a message to be displayed inside the app when they get the golden ticket.
                                                Example: You've won one of you summer golden ticket. Halloween golden tickets etc.
                                            </p>
                                        </Modal>
                                        <Button icon={<SyncOutlined />} onClick={() => loadGoldenTickets()}></Button>
                                        <Button type="primary" onClick={() => setOpenDrawer(true)} icon={<PlusCircleOutlined />}>
                                            Add New
                                        </Button>
                                    </Space>
                                </Divider>
                            </Col>
                        </Row>
                        <Segmented options={["Available", "Redeemed"]} onChange={(value) => setRedeemed(value)} />
                        {/* <Alert message="This feature has not been rolled out to apps yet" type="warning" /> */}
                        <GoldenTicketTable tickets={goldenTickets} reload={loadGoldenTickets} />
                    </Space>

                    {/* <Typography.Text>{`${JSON.stringify(goldenTickets)}`}</Typography.Text> */}
                </Row>
            </div>

            <NewGoldenTicketPanel onClose={() => onClose()} openDrawer={openDrawer} />
        </>
    );
}
