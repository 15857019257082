import React from "react";

import {
    Button,
    // Col,
    // Drawer,
    // Spin,
    Tag,
    Space,
    Descriptions,
    Typography
} from "antd";

import * as Constants from "../../utils/Constants";
import * as Global from "../../utils/Global";

import { PassengerList } from "./passengers/PassengerList";

import { PassengerIconsSummary } from "./passengers/PassengerIconsSummary";

const TicketViewer = (props) => {
    const { singleTicket } = props;

    return singleTicket === null ? (
        <Typography.Text>No Ticket Selected.</Typography.Text>
    ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Descriptions title="Ticket" layout="vertical" bordered>
                {/* ROW */}
                <Descriptions.Item label="Customer" span={2}>
                    <Space direction="vertical">
                        <Typography.Text>{singleTicket.name}</Typography.Text>
                        <a
                            target={"_blank"}
                            rel="noopener noreferrer"
                            href={"mailto:" + singleTicket.email + ""}
                        >
                            {singleTicket.email}
                        </a>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    <Tag
                        color={Constants.getPaidStatusColour(
                            singleTicket.status
                        )}
                    >
                        {Constants.getPaidStatus(singleTicket.status)}
                    </Tag>
                </Descriptions.Item>
                {/* ROW */}
                {/* <Descriptions.Item label="Special Requirements" span={2}>
                        {singleTicket.meta.specialreq ? singleTicket.meta.specialreq : "No special reqs"}
                    </Descriptions.Item> */}
                <Descriptions.Item label={"Special Requirements"} span={2}>
                    <Space direction="vertical">
                        {Global.cars && singleTicket.meta !== undefined ? (
                            <Typography.Text>
                                Car Details:{" "}
                                {singleTicket.meta.cardetails
                                    ? singleTicket.meta.cardetails
                                    : "No Car Details"}
                            </Typography.Text>
                        ) : (
                            <></>
                        )}
                        {singleTicket.meta !== undefined &&
                        singleTicket.meta != null ? (
                            <Typography.Text>
                                Special Reqs:{" "}
                                {singleTicket.meta.specialreq
                                    ? singleTicket.meta.specialreq
                                    : "No Special Requirements"}
                            </Typography.Text>
                        ) : (
                            <></>
                        )}
                        {singleTicket.smartpass ? (
                            <Typography.Text>
                                {singleTicket.smartpass
                                    ? `SmartPass IDs: ${singleTicket.smartpass.map(
                                          (p) => (
                                              <Typography.Text>
                                                  {p}
                                              </Typography.Text>
                                          )
                                      )}`
                                    : ""}
                            </Typography.Text>
                        ) : (
                            <></>
                        )}
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                    {singleTicket.meta
                        ? singleTicket.meta.number
                            ? singleTicket.meta.number
                            : "No number provided"
                        : ""}
                </Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label="Ticket" span={2}>
                    {/* <Badge status="processing" text="Running" /> */}
                    <div>
                        Out:{" "}
                        {Constants.getJourneyString(
                            singleTicket.ticket.outJourney
                        )}{" "}
                        on{" "}
                        {Constants.getShortDateString(
                            singleTicket.ticket.outDate
                        )}{" "}
                        at {Constants.getTimestamp(singleTicket.ticket.outTime)}
                    </div>
                    <br />
                    {singleTicket.ticket.returnTicket ? (
                        <div>
                            Return:{" "}
                            {Constants.getJourneyString(
                                singleTicket.ticket.outJourney === "dm"
                                    ? "dt"
                                    : "dm"
                            )}{" "}
                            on{" "}
                            {Constants.getShortDateString(
                                singleTicket.ticket.returnDate
                            )}{" "}
                            at{" "}
                            {Constants.getTimestamp(
                                singleTicket.ticket.returnTime
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                    {Constants.getFormattedPrice(singleTicket.price)}
                </Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label="Passengers" span={2}>
          
                    <PassengerList
                        newTicket={singleTicket}
                        showRemove={false}
                        showTotals={false}
                        removePassenger={() => console.log("")}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Total Passengers">
                    <PassengerIconsSummary
                        passengers={singleTicket.passengers}
                    />
                </Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label="Date Bought">
                    {Constants.getShortDateString(singleTicket.datecreated)}
                </Descriptions.Item>
                <Descriptions.Item label="Stripe Reference">
                    {singleTicket.payment.status === "reserved" ? (
                        <Typography.Text>No Online Payment</Typography.Text>
                    ) : (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://dashboard.stripe.com/payments/${singleTicket.payment.stripePayment}`}
                        >
                            {singleTicket.payment.stripePayment}
                        </a>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Method">
                    {Constants.getMethodIcon(singleTicket.method)}
                </Descriptions.Item>
                {/* ROW */}
                <Descriptions.Item label={"Ticket and User ID"} span={3}>
                    <Space direction="vertical">
                        <Typography.Text>{`Ticket ID: ${singleTicket._id}`}</Typography.Text>
                        <Typography.Text>
                            {singleTicket.userid
                                ? `User ID: ${singleTicket.userid}`
                                : "Guest"}
                        </Typography.Text>
                    </Space>
                </Descriptions.Item>
            </Descriptions>

            <Button
                target="_blank"
                rel="noopener noreferrer"
                href={`${Constants.websiteURL}/tickets/confirmation?t=${singleTicket._id}`}
            >
                View On Web
            </Button>
        </Space>
    );
};

export default TicketViewer;
