import React, { useEffect, useState } from "react";

import {
    Divider,
    Button,
    Row,
    Col,
    Drawer,
    Space,
    Radio,
    Input,
    Descriptions,
    Form,
    Checkbox,
    Select,
    message,
    Typography,
    TimePicker,
    DatePicker,
    InputNumber,
    Alert
} from "antd";

import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";

import * as Constants from "../../../utils/Constants";
import * as RecurringTicketService from "../../../services/RecurringTicketService";

import * as TimeUtils from "../../../utils/TimeUtils";

import { NewTicketFindAccount } from "../newticket/NewTicketFindAccount";
import { JourneyPicker } from "../../common/JourneyPicker";
import { PassengerListBuilder } from "../passengers/PassengerListBuilder";
import { format } from "date-fns";
import moment from "moment";

import PreviewRecurringTicketsModal from "./PreviewRecurringTicketsModal";

const timeFormat = "HH:mm";
const { Option } = Select;

const validateTicket = function (ticket) {
    let error = null;

    if (ticket.name === "" || ticket.email === "") {
        error = "We need a name and email";
    }
    if (ticket.name === null || ticket.email === null) {
        error = "We need a name and email";
    }

    if (ticket.ticket.returnTicket) {
        if (ticket.ticket.outDate === null || ticket.ticket.returnDate === null) {
            error = "We need a out date and return date";
        }
        if (ticket.ticket.outTime === null || ticket.ticket.returnTime === null) {
            error = "We need a out date and return time";
        }
    } else {
        if (ticket.ticket.outDate === null) {
            error = "We need a out date date";
        }

        if (ticket.ticket.outTime === null) {
            error = "We need a out date time";
        }
    }

    if (ticket.passengers.length == 0) {
        error = "No passengers selected";
    }

    // TIMES
    if (Array.isArray(ticket.ticket.outTime) || Array.isArray(ticket.ticket.returnTime)) {
        error = "Times aren't selected";
    }
    if (error !== null) {
        return error;
    } else {
        return true;
    }
};

const starterTicket = {
    userid: null,
    name: null,
    email: null,
    ticket: {
        returnTicket: true,
        outJourney: "dm",
        outTime: 0,
        outReroute: false,
        returnTime: 0,
        returnReroute: false,
        passengers: {}
    },
    recurrance: {
        startDate: new Date(),
        type: "week",
        days: [],
        duration: 1
    },
    price: 0,
    promo: null,
    method: "admin",
    status: "reserved",
    payment: {
        status: "reserved",
        provider: "admin"
    },
    meta: {
        specialreq: null,
        cardetails: null,
        number: null,
        smartpass: null
    },
    deleted: false,
    passengers: []
};

export default function NewRecurringTicketPanel(props) {
    const { onClose, width, visible } = props;

    const [newTicket, setNewTicket] = useState(starterTicket);
    const [update, setUpdate] = useState(0);
    const [recurRepeat, setRecurRepeat] = useState("week");
    const [recurDuration, setRecurDuration] = useState(1);
    const [monthlyDate, setMonthlyDate] = useState(1);
    const [days, setDays] = useState([]);
    const [ticketEstimate, setTicketEstimate] = useState(0);
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        let _ticketEstimate = days.length * recurDuration;
        setTicketEstimate(_ticketEstimate);
    }, [days, recurDuration]);

    function selectDays(e) {
        // console.log(e.target.value);
        setDays(e);
        // recurranceChange();
    }

    function changeTime(type, time) {
        if (time != null && time !== undefined) {
            // console.log(type);
            // console.log(time._d);
            // console.log(time._d.getHours());
            // console.log(time._d.getMinutes());
            const timeNumerical = time._d.getHours() * 100 + time._d.getMinutes();
            if (type === "returnTime") {
                setReturnTime(timeNumerical, false);
            } else {
                setOutTime(timeNumerical, false);
            }
        }
    }

    function resetTicket() {
        setNewTicket(starterTicket);
        updateTicket("name", "");
        updateTicket("email", "");
        updateMetaTicket("number", "");
        updateMetaTicket("cardetails", "");
        updateMetaTicket("specialreq", "");
        changeJourney("dm");
        updateTicketDetails("returnTicket", true);
        updateTicket("outTime", 0);
        updateTicket("returnTime", 0);
        updateTicket("status", "reserved");
        updateTicket("passengers", []);
        updateAccount(null);
        updateTicketDetails("passengers", {});
        setUpdate(0);
        setDays([]);
        setRecurDuration(1);
        setMonthlyDate(1);
        updateRecurrance("type", "week");
        updateRecurrance("days", []);
        updateRecurrance("duration", 1);
        updateRecurrance("startDate", new Date());
    }

    // function updatePassengerList(passenger, value) {
    //     let _ticket = newTicket;
    //     _ticket.ticket.passengers[passenger] = value;
    //     setNewTicket(_ticket);
    //     setUpdate(update + 1);
    // }

    useEffect(() => {
        let _newTicket = newTicket;
        _newTicket.recurrance.type = recurRepeat;
        _newTicket.recurrance.days = days;
        _newTicket.recurrance.duration = recurDuration;
        _newTicket.recurrance.startDate = startDate;
        setNewTicket(_newTicket);
    }, [recurRepeat, days, startDate, recurDuration]);

    function updateRecurrance(type, value) {
        let _ticket = newTicket;
        _ticket.recurrance[type] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    function updateMetaTicket(meta, value) {
        let _ticket = newTicket;
        _ticket.meta[meta] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    async function changeJourney(journey) {
        updateTicketDetails("outJourney", journey);
    }

    function setOutTime(timeString, reroute) {
        // console.log(timeString)
        let tempTicket = newTicket;
        tempTicket.ticket.outTime = timeString;
        tempTicket.ticket.outReroute = reroute;
        setNewTicket(tempTicket);
        setUpdate(update + 1);
    }

    function setReturnTime(timeString, reroute) {
        let tempTicket = newTicket;
        tempTicket.ticket.returnTime = timeString;
        tempTicket.ticket.returnReroute = reroute;
        setNewTicket(tempTicket);
        setUpdate(update + 1);
    }

    function updateTicket(property, value) {
        // console.log(value);
        let _ticket = newTicket;
        _ticket[property] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    function updateTicketDetails(property, value) {
        let _ticket = newTicket;
        _ticket.ticket[property] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    function updateAccount(pickedAccount) {
        // console.log(accountid);
        if (pickedAccount === null) return;
        const selectedAccount = JSON.parse(pickedAccount);
        let _ticket = newTicket;
        // let _account = accounts.find((a) => a._id === accountid)
        // console.log(_account);
        // console.log(selectedAccount);

        if (selectedAccount.accountType === "temp") {
            _ticket.userid = null;
            // GET NAME
            _ticket.name = selectedAccount.name;
            // GET EMAIL
            _ticket.email = selectedAccount.email;
            // GET NUMBER
            _ticket.meta.number = selectedAccount.number;
            _ticket.meta.cardetails = selectedAccount.cardetails;
            setNewTicket(_ticket);
            setUpdate(update + 1);
        } else {
            if (selectedAccount._id === null) {
                // console.log("clearing ticket");
                // clearTicket
                _ticket.userid = null;
                // GET NAME
                _ticket.name = "";
                // GET EMAIL
                _ticket.email = "";
                // GET NUMBER
                _ticket.meta.number = "";
                setNewTicket(_ticket);
                setUpdate(update + 1);
            } else {
                // console.log(accountid);
                _ticket.userid = selectedAccount._id;
                // GET NAME
                _ticket.name = selectedAccount.displayName;
                // GET EMAIL
                _ticket.email = selectedAccount.email;
                // GET NUMBER
                _ticket.meta.number = selectedAccount.phoneNumber;
                _ticket.meta.cardetails = selectedAccount.cardetails;
                // console.log(_ticket)
                setNewTicket(_ticket);
                setUpdate(update + 1);
            }
        }
    }

    async function saveTicketToServer() {
        if (newTicket.email === "" || newTicket.email === null) {
            updateTicket("email", `quickticket@thearranmoreferry.com`);
        }

        let _newTicket = newTicket;
        // _newTicket.recurrance.startDate = NormalizeDate(
        //     _newTicket.recurrance.startDate
        // ).getTime();
        _newTicket.recurrance.startDate = startDate.getTime();

        setNewTicket(_newTicket);
        // recurrance: {
        //     startDate: new Date(),
        //     type: "week",
        //     days: [],
        //     duration: 1
        // },

        // VALIDATE TICKET FIRST
        if (validateTicket(newTicket) === true) {
            let response = await RecurringTicketService.addNewTicket(newTicket);
            if (response.status === 200) {
                message.success("Recurring Ticket Added");
                resetTicket();
                onClose();
            } else {
                message.error("Recurring Ticket cannot be added to the server");
            }
        } else {
            message.error(`Recurring Ticket cannot be added to the server: ${validateTicket(newTicket)}`);
        }
    }

    //
    function updatePassengers(passenger) {
        // console.log(passenger);
        // console.log(newTicket.passengers);
        let currentPassengers = newTicket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(passenger.code);
        if (matchingCodeIndex > -1) {
            currentPassengers[matchingCodeIndex].quantity += 1;
        } else {
            currentPassengers.push(passenger);
        }
        updateTicket("passengers", currentPassengers);
    }
    //
    function removePassenger(code) {
        let currentPassengers = newTicket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(code);
        if (matchingCodeIndex > -1) {
            if (currentPassengers[matchingCodeIndex].quantity > 1) {
                currentPassengers[matchingCodeIndex].quantity -= 1;
            } else {
                currentPassengers.splice(matchingCodeIndex, 1);
            }
        }
        updateTicket("passengers", currentPassengers);
    }

    function clearAccount() {
        // console.log("CLEARING");
        updateTicket("name", "");
        updateTicket("email", "");
        updateMetaTicket("number", "");
        updateMetaTicket("cardetails", "");
        updateMetaTicket("specialreq", "");
    }

    return (
        <>
            <Drawer
                title="New Recurring Ticket"
                placement="right"
                closable={true}
                closeIcon={<CloseSquareOutlined />}
                width={width}
                onClose={onClose}
                open={visible}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Descriptions layout="vertical" column={2} size="small" bordered>
                        {/* ROW */}
                        <Descriptions.Item span={2} label="Customer Details">
                            <NewTicketFindAccount clearAccount={() => clearAccount()} updateAccount={(id) => updateAccount(id)} />
                            <Form
                                labelCol={{
                                    span: 8
                                }}
                                wrapperCol={{
                                    span: 16
                                }}
                            >
                                <Form.Item label="Name">
                                    <Input
                                        placeholder="First Name Surname"
                                        onChange={(v) => updateTicket("name", v.target.value)}
                                        value={newTicket.name}
                                    />
                                </Form.Item>
                                <Form.Item label="Email">
                                    <Input
                                        placeholder="admin@arranmoreferry.com"
                                        onChange={(v) => updateTicket("email", v.target.value)}
                                        value={newTicket.email}
                                    />
                                </Form.Item>
                                <Form.Item label="Number">
                                    <Input
                                        placeholder="+353 XXX XXXX"
                                        onChange={(v) => updateMetaTicket("number", v.target.value)}
                                        value={newTicket.meta.number}
                                    />
                                </Form.Item>
                                <Form.Item label="Car Details">
                                    <Input
                                        placeholder="Car Type"
                                        onChange={(v) => updateMetaTicket("cardetails", v.target.value)}
                                        value={newTicket.meta.cardetails}
                                    />
                                </Form.Item>
                                <Form.Item label="Special Reqs">
                                    <Input
                                        placeholder="Wheelchair Access, Bike Rack etc"
                                        onChange={(v) => updateMetaTicket("specialreq", v.target.value)}
                                    />
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions layout="vertical" column={2} size="small" bordered>
                        {/* STATUS */}
                        <Descriptions.Item span={2} label="Status">
                            <Radio.Group
                                value={newTicket.status}
                                onChange={(e) => updateTicket("status", e.target.value)}
                                buttonStyle="solid"
                            >
                                <Radio.Button value={"reserved"}>Reserved</Radio.Button>
                                <Radio.Button value={"succeeded"}>Paid</Radio.Button>
                            </Radio.Group>
                        </Descriptions.Item>
                        {/* TICKET DETAILS */}
                        <Descriptions.Item span={2} label="Return Ticket">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Radio.Group
                                    value={newTicket.ticket.returnTicket}
                                    onChange={(e) => updateTicketDetails("returnTicket", e.target.value)}
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value={true}>Return</Radio.Button>
                                    <Radio.Button value={false}>One Way</Radio.Button>
                                </Radio.Group>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Journey">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <JourneyPicker
                                    journey={newTicket.ticket.outJourney}
                                    onJourneyChange={(e) => changeJourney(e.target.value)}
                                />

                                {newTicket.ticket.returnTicket ? (
                                    <Typography.Text>
                                        {`Return Journey: ${
                                            newTicket.ticket.outJourney === "dm" ? "Departing from Tory" : "Departing Magheroarty"
                                        }`}
                                    </Typography.Text>
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Passengers">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <PassengerListBuilder
                                    updatePassengers={(t) => updatePassengers(t)}
                                    removePassenger={removePassenger}
                                    newTicket={newTicket}
                                />
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions layout="vertical" column={2} size="small" bordered>
                        <Descriptions.Item span={2} label="Travel Dates">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Row type="flex">
                                    <Space direction="vertical">
                                        <Col xs={24}>
                                            <Space style={{ width: "100%" }} direction="vertical">
                                                <Space direction="horizontal" style={{ width: "100%" }}>
                                                    <Typography.Text>Starting on:</Typography.Text>
                                                    <DatePicker
                                                        value={new moment(startDate)}
                                                        onChange={(v) => {
                                                            const newDate = new Date(v._d);
                                                            setStartDate(newDate);
                                                        }}
                                                    />
                                                </Space>
                                                <Space direction="horizontal" style={{ width: "100%" }}>
                                                    <Space
                                                        direction="horizontal"
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        <Typography.Text>Repeats Every</Typography.Text>
                                                        <Select defaultValue={recurRepeat} onSelect={setRecurRepeat}>
                                                            <Option value="week">Week</Option>
                                                            {/* <Option value="month">
                                                                Month
                                                            </Option> */}
                                                        </Select>
                                                    </Space>
                                                    <Space
                                                        direction="horizontal"
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        <Typography.Text>For</Typography.Text>
                                                        <InputNumber defaultValue={recurDuration} onChange={setRecurDuration} max={12} />
                                                        <Typography.Text>{`${recurRepeat}${recurDuration > 1 ? "s" : ""}`}</Typography.Text>
                                                    </Space>
                                                </Space>

                                                {recurRepeat === "week" ? (
                                                    <Space direction="vertical">
                                                        <Typography.Text level={4}>On Days:</Typography.Text>
                                                        {/* <Typography.Text>{`${days}`}</Typography.Text> */}
                                                        <Checkbox.Group
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            value={days}
                                                            onChange={selectDays}
                                                        >
                                                            <Row>
                                                                {Constants.days.map((i, index) => {
                                                                    return (
                                                                        <Col key={i} span={8}>
                                                                            <Checkbox checked={days.includes(index)} value={index}>
                                                                                {Constants.days[index]}
                                                                            </Checkbox>
                                                                        </Col>
                                                                    );
                                                                })}
                                                            </Row>
                                                        </Checkbox.Group>

                                                        {/* <Typography.Text
                                                            level={5}
                                                        >
                                                            Months
                                                        </Typography.Text> */}

                                                        {/* <Checkbox.Group
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            value={months}
                                                            onChange={
                                                                selectMonths
                                                            }
                                                        >
                                                            <Row>
                                                                {Constants.months.map(
                                                                    (
                                                                        i,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Col
                                                                                key={
                                                                                    i
                                                                                }
                                                                                span={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Checkbox
                                                                                    checked={months.includes(
                                                                                        index
                                                                                    )}
                                                                                    value={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        Constants
                                                                                            .months[
                                                                                            index
                                                                                        ]
                                                                                    }
                                                                                </Checkbox>
                                                                            </Col>
                                                                        );
                                                                    }
                                                                )}
                                                            </Row>
                                                        </Checkbox.Group> */}
                                                    </Space>
                                                ) : (
                                                    <Space direction="vertical">
                                                        <Typography.Text level={5}>
                                                            On Date: {`${format(new Date("2021/01/01").setDate(monthlyDate), "do")}`}
                                                        </Typography.Text>

                                                        <Input type="number" onChange={(v) => setMonthlyDate(v.target.value)} />
                                                    </Space>
                                                )}

                                                <Typography.Title
                                                    style={{
                                                        paddingTop: "1em"
                                                    }}
                                                    level={5}
                                                >
                                                    Out Time
                                                </Typography.Title>

                                                <TimePicker
                                                    defaultValue={moment(TimeUtils.getTimePickerTime(newTicket.ticket.outTime), timeFormat)}
                                                    onChange={(v) => changeTime("outTime", v)}
                                                    format={timeFormat}
                                                />
                                            </Space>
                                        </Col>
                                        {newTicket.ticket.returnTicket ? (
                                            <Col xs={24}>
                                                <Space style={{ width: "100%" }} direction="vertical">
                                                    <Typography.Title level={5}>Return Time</Typography.Title>

                                                    <TimePicker
                                                        defaultValue={moment(
                                                            TimeUtils.getTimePickerTime(newTicket.ticket.returnTime),
                                                            timeFormat
                                                        )}
                                                        onChange={(v) => changeTime("returnTime", v)}
                                                        format={timeFormat}
                                                    />
                                                </Space>
                                            </Col>
                                        ) : (
                                            <></>
                                        )}

                                        <Divider
                                            style={{
                                                margin: "5px 0px 5px 0px"
                                            }}
                                        />
                                        <PreviewRecurringTicketsModal ticket={newTicket} />
                                    </Space>
                                </Row>
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>

                    <Alert
                        style={{ marginTop: "1em", marginBottom: "2em" }}
                        type="error"
                        message={
                            ticketEstimate <= 0
                                ? "Warning: This can generate a lot of tickets on the system."
                                : `Warning: This will generate ${ticketEstimate} tickets on the system.`
                        }
                    />

                    {/* <Typography.Text>{`${JSON.stringify(newTicket)}`}</Typography.Text> */}

                    {validateTicket(newTicket) ? (
                        <Row type="flex" align="middle">
                            <Button onClick={() => resetTicket()} icon={<SaveOutlined />} type="ghost" danger>
                                Clear Ticket
                            </Button>
                            <Divider orientation="right">
                                <Button onClick={saveTicketToServer} icon={<SaveOutlined />} type="primary">
                                    Add Recurring Ticket
                                </Button>
                            </Divider>
                        </Row>
                    ) : (
                        <></>
                    )}
                </Space>
            </Drawer>
        </>
    );
}
