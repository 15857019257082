import React from "react";

import {
    Radio,
} from "antd";

export function JourneyPicker(props) {

    const { onJourneyChange, journey } = props;

    return <Radio.Group
        value={journey}
        onChange={onJourneyChange}
        buttonStyle="solid"
    >
        <Radio.Button value="dm">Departing Magheroarty</Radio.Button>
        <Radio.Button value="dt">Departing Tory</Radio.Button>
    </Radio.Group>
}