import React, { useContext, useEffect, useState } from "react";

import { Divider, Button, Drawer, Space, Input, Form, Typography, Checkbox, message, InputNumber } from "antd";

import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { getFormattedPrice, maxDeckSpacesAvailable } from "../../utils/Constants";
import * as PriceService from "../../services/PriceService";
import { UserContext } from "../../provider/UserProvider";

export default function EditPricesPanel(props) {
    const { visible, width, onClose } = props;

    const editablePrice = visible;

    useEffect(() => {
        if (editablePrice) {
            setEditablePrice();
        }
    }, [editablePrice]);

    function setEditablePrice() {
        // console.log("Price changed");
        setID(editablePrice._id);
        setLabel(editablePrice.label);
        setLabelIE(editablePrice.labelIE);
        setCode(editablePrice.code);
        setPassengers(editablePrice.passengers);
        setCars(editablePrice.cars);
        setDeckSpaces(editablePrice.deckSpaces ?? 0);
        setPrice(editablePrice.price);
        setIslander(editablePrice.islander);
        setAdminOnly(editablePrice.adminOnly);
    }

    const user = useContext(UserContext);

    const [id, setID] = useState("");
    const [code, setCode] = useState("");
    const [label, setLabel] = useState("");
    const [labelIE, setLabelIE] = useState("");
    const [passengers, setPassengers] = useState(0);
    const [cars, setCars] = useState(0);
    const [price, setPrice] = useState(0);
    const [deckSpaces, setDeckSpaces] = useState(0);
    const [islander, setIslander] = useState(false);
    const [adminOnly, setAdminOnly] = useState(false);

    async function updatePrice() {
        console.log(`${id}, ${label}, ${labelIE}, ${passengers}, ${cars}, ${price}, ${islander} ${user}`);
        // VALIDATE
        if (label === "" && labelIE === "") {
            message.error("You need to include a label in English and Gaeilge");
        }
        if (passengers === 0 && cars === 0) {
            message.error("You need add at least one type of passenger");
        }
        const priceObject = {
            id,
            code,
            label,
            labelIE,
            passengers,
            cars,
            deckSpaces,
            price,
            islander,
            adminOnly
        };
        // POST TO SERVICE
        let response = await PriceService.updatePrice(priceObject, user._id);
        // GET RESPONSE
        if (response.status === 200) {
            message.success("You've updated the price the price");
            onClose();
        } else {
            message.error("You can't updated this price right now.");
        }
    }

    async function deletePrice() {
        // POST TO SERVICE
        let response = await PriceService.removePrice(id, user);
        // GET RESPONSE
        if (response.status === 200) {
            message.success("You've deleted the price");
            onClose();
        } else {
            message.error("You can't delete this price right now.");
        }
    }

    return (
        <>
            <Drawer
                title="Edit Price"
                placement="right"
                closable={true}
                closeIcon={<CloseSquareOutlined />}
                width={width}
                onClose={() => onClose()}
                open={visible}
            >
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    {/* <Typography.Text>{`${id}`}</Typography.Text> */}
                    <Form layout={"vertical"}>
                        <Form.Item label="Price Label">
                            <Input value={label} placeholder="Label for Ticket" onChange={(v) => setLabel(v.target.value)} />
                        </Form.Item>
                        <Form.Item label="Price Label Gaeilge">
                            <Input value={labelIE} placeholder="Label for Ticket in Gaeilge" onChange={(v) => setLabelIE(v.target.value)} />
                        </Form.Item>
                        <Form.Item label="Code">
                            <Input value={code} placeholder="Code" onChange={(v) => setCode(v.target.value)} />
                        </Form.Item>
                        <Form.Item label="Number of Passengers">
                            <InputNumber value={passengers} onChange={(v) => setPassengers(v)} />
                        </Form.Item>
                        {maxDeckSpacesAvailable === 0 ? (
                            <></>
                        ) : (
                            <Form.Item label="Number of Cars">
                                <InputNumber value={cars} onChange={(v) => setCars(v)} />
                            </Form.Item>
                        )}
                        {maxDeckSpacesAvailable === 0 ? (
                            <></>
                        ) : (
                            <Form.Item label="Number of Deck Spaces (Motorbike = 1, Car = 2, LWB Van =3, Lorry = 4">
                                <InputNumber value={deckSpaces} onChange={(v) => setDeckSpaces(v)} />
                            </Form.Item>
                        )}
                        <Form.Item label="Price in ¢">
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <InputNumber value={price} onChange={(v) => setPrice(v)} />
                                <Typography.Text>{getFormattedPrice(price)}</Typography.Text>
                            </Space>
                        </Form.Item>
                        <Form.Item label="Island Ticket">
                            <Checkbox checked={islander} onChange={(v) => setIslander(v.target.checked)}>
                                Check box for islander ticket
                            </Checkbox>
                        </Form.Item>
                        <Form.Item label="Admin Ticket">
                            <Checkbox checked={adminOnly} onChange={(v) => setAdminOnly(v.target.checked)}>
                                Check box for islander ticket
                            </Checkbox>
                        </Form.Item>
                    </Form>
                    <Divider orientation="right">
                        <Button type="primary" onClick={() => updatePrice()} icon={<SaveOutlined />}>
                            Update Price
                        </Button>
                    </Divider>
                    <Typography.Title level={5}>Actions</Typography.Title>
                    <Button danger onClick={() => deletePrice()}>
                        Delete
                    </Button>
                </Space>
            </Drawer>
        </>
    );
}
