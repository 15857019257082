import React, {
  // Component 
} from 'react';

import UserProvider from './provider/UserProvider';
import Application from './components/Application';

import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

function App() {
  let defaultTheme = localStorage.getItem('theme') ?? "light";
  return (
    <UserProvider>
      <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme}>
        <Application />
      </ThemeSwitcherProvider>
    </UserProvider>
  )
}

export default App