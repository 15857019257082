import React from "react";
// CSS
import "../../pages/css/Timetables.css";

import { Row, Col } from "antd";

import * as Constants from "../../utils/Constants";
import { DayCells } from "./DayCells";
import { DayLabels } from "./DayLabels";
import { Typography } from "antd";

export function MonthlyTable(props) {
    const { journey, days, months, only, times } = props;

    return (
        <>
            <Row className={journey === "dt" ? "primary" : "secondary"} style={{ textAlign: "center" }}>
                <Col span={24}>
                    <span className={"table-title pb-1"}>{journey === "dt" ? "Departing Tory" : "Departing Magheroarty"}</span>
                </Col>
            </Row>
            {/* <Typography.Text>{`days: ${days}, months ${months}, only: ${only}, times: ${times}`}</Typography.Text> */}
            {days.map((i, index) => {
                return (
                    <div key={index}>
                        <Row
                            className={
                                journey === "dt"
                                    ? !Constants.getTheme()
                                        ? "primary-light white-bg"
                                        : "primary-light dark-bg"
                                    : !Constants.getTheme()
                                    ? "secondary-light white-bg"
                                    : "secondary-light dark-bg"
                            }
                            style={{ textAlign: "center" }}
                        >
                            <Col span={24}>
                                <DayLabels dayArray={i} only={only} />
                            </Col>
                        </Row>
                        <DayCells journey={journey} months={months} days={i} times={times} only={only} />
                    </div>
                );
            })}
        </>
    );
}
