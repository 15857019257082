import React from "react"; // useEffect // useState, // Component,

import { Button, Table, Space, Typography } from "antd";

import * as Constants from "../../utils/Constants";
import * as PassengerUtils from "../../utils/PassengerUtils";
import * as CarUtils from "../../utils/CarUtils";
import { showPrices } from "../../utils/Global";
import { PassengerIconsSummary } from "./passengers/PassengerIconsSummary";
import TicketStatus from "./TicketStatus";
import { format } from "date-fns";

export default function TicketTable(props) {
    const { tableChange, showDateCreated, viewTicket } = props;

    let data = props.tickets;

    // console.log(data);
    // data.map((d) => d.key == d._id);
    data.forEach((d) => (d.key = d._id));

    const columns = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            width: "7%",
            render: (value) => (
                <Button
                    type="link"
                    onClick={() => viewTicket(value)}
                    style={{ padding: "0px" }}
                >
                    {Constants.getIDString(value).toUpperCase()}
                </Button>
            ),
            sorter: (a, b) => {
                if (a._id < b._id) {
                    return -1;
                }
                if (a._id > b._id) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Name",
            key: "name",
            width: "15%",
            render: (value, row) => (
                <Space direction="vertical" size="small">
                    {row.name === null ||
                    row.name === "" ||
                    row.name === "null" ? (
                        <Typography.Text>-</Typography.Text>
                    ) : (
                        <Typography.Text>{`${row.name}`}</Typography.Text>
                    )}
                    <Typography.Text style={{ fontSize: "0.8em" }}>
                        <a href={"mailto:" + row.email}>{row.email}</a>
                    </Typography.Text>
                </Space>
            ),
            sorter: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
            // ...getColumnSearchProps('nameEmail'),
        },
        {
            title: "Ticket",
            dataIndex: "dates",
            width: "25%",
            key: "dates",
            render: (value, row) => (
                <Space direction="vertical">
                    <Typography.Text>
                        {`${Constants.getJourneyString(
                            row.ticket.outJourney
                        )} on ${Constants.getNumericalDateString(
                            row.ticket.outDate
                        )} at  ${Constants.getTimestamp(row.ticket.outTime)}`}
                    </Typography.Text>
                    {row.ticket.openReturn ? (
                        <Typography.Text style={{ fontSize: "0.8em" }}>
                            {`Open Return`}
                        </Typography.Text>
                    ) : row.ticket.returnTicket ? (
                        <Typography.Text style={{ fontSize: "0.8em" }}>
                            {`Return: ${Constants.getJourneyString(
                                row.ticket.outJourney === "dm" ? "dt" : "dm"
                            )} on ${Constants.getNumericalDateString(
                                row.ticket.returnDate
                            )} at ${Constants.getTimestamp(
                                row.ticket.returnTime
                            )}`}
                        </Typography.Text>
                    ) : (
                        <Typography.Text style={{ fontSize: "0.8em" }}>
                            Single Ticket
                        </Typography.Text>
                    )}
                    {row.meta === undefined || row.meta === null ? (
                        <></>
                    ) : row.meta.specialreq !== "" &&
                      row.meta.specialreq !== null ? (
                        <Typography.Text
                            italic={true}
                        >{`Special Req: ${row.meta.specialreq}`}</Typography.Text>
                    ) : (
                        <></>
                    )}
                </Space>
            )
        },
        {
            title: "Pass.",
            render: (value, row) => (
                <>
                    {/* <Typography.Text>{`${JSON.stringify(row.ticket.passengers)} ${JSON.stringify(row.meta)}`}</Typography.Text> */}
                    <PassengerIconsSummary
                        passengers={row.passengers}
                        cardetails={
                            row.meta === undefined || row.meta === null
                                ? ""
                                : row.meta.cardetails ?? ""
                        }
                    />
                </>
            ),
            width: "5%",
            // ellipsis: true,
            sorter: (a, b) => {
                const aIndex =
                    CarUtils.getSingleTicketCarTotal(a.ticket) * 100 +
                    PassengerUtils.SingleTicketPassengerTotal(a.ticket.passengers);
                const bIndex =
                    CarUtils.getSingleTicketCarTotal(b.ticket) * 100 +
                    PassengerUtils.SingleTicketPassengerTotal(b.ticket.passengers);
                if (aIndex < bIndex) {
                    return -1;
                }
                if (aIndex > bIndex) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value) => <TicketStatus value={value} />,
            // ellipsis: true,
            width: "10%",
            sorter: (a, b) => {
                const astatus = Constants.getPaidStatus(a.status);
                const bstatus = Constants.getPaidStatus(b.status);
                if (astatus < bstatus) {
                    return -1;
                }
                if (astatus > bstatus) {
                    return 1;
                }
                return 0;
            }
        },
        {
            title: "Actions",
            dataIndex: "_id",
            key: "_id",
            fixed: "right",
            render: (value) => (
                <Button type="outline" onClick={() => props.editTicket(value)}>
                    Edit
                </Button>
            ),
            width: "12%"
        }
    ];

    if (showDateCreated) {
        columns.splice(5, 0, {
            title: "Date Created",
            dataIndex: "datecreated",
            key: "datecreated",
            render: (value) => `${format(value, "d/M/YYY, HH:mm")}`,
            width: "8%",
            // ellipsis: true,
            defaultSortOrder: "descend",
            sorter: (a, b) => a.datecreated - b.datecreated
        });
    }

    // console.log(showPrices())
    if (showPrices()) {
        // console.log("splice")
        columns.splice(4, 0, {
            title: "Total",
            dataIndex: "price",
            key: "price",
            render: (value) => <>{Constants.getFormattedPrice(value)}</>,
            width: "10%",
            sorter: (a, b) => a.price - b.price
        });
    }

    if (!props.archive) {
        columns.filter((c) => c.title === "Archive");
    }

    // setFilteredInfo()
    // let { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                pagination={
                    data.length > 20
                        ? { defaultPageSize: 20, total: 100 }
                        : false
                }
                scroll={{ x: 1200 }}
                // onChange={tableChange}
            />
        </>
    );
}
