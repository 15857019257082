import React from "react";
import { Space, DatePicker, Tag, Typography, Spin, Button, Card, Col, Row } from "antd";
import moment from "moment";
import * as TimeUtils from "../../../utils/TimeUtils";
import FerryTypeLabel from "../FerryTypeLabel";
import { ArrowRightOutlined, CarOutlined, LockOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import * as GlobalUtils from "../../../utils/Global";
import { FaCalendarDay, FaShip, FaShuttleVan } from "react-icons/fa";
import { TbBike, TbTruck } from "react-icons/tb";
import { format, isAfter, isBefore, isSameDay, sub } from "date-fns";
import V2MobileBottomSheet from "../mobile/V2MobileBottomSheet";
export function DateTimeKeys(props) {
    const { date, setDate, timeKeyData, dateTimeKey, setDateTimeKey, setNearestTimeKey, isLoading } = props;

    const nowTime = isSameDay(new Date(), date) || isAfter(new Date(), date) ? parseInt(format(new Date(), "HHmm")) : 0;

    return isLoading ? (
        <Space>
            <Spin />
        </Space>
    ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
            <DatePicker defaultValue={new moment(date)} style={{ width: "100%" }} size="large" onChange={(v) => setDate(new Date(v._d))} />
            {/* <Typography.Text>{`${date}`}</Typography.Text>
            <Typography.Text>{`${nowTime}`}</Typography.Text> */}
            {window.innerWidth > 900 ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                    {isSameDay(new Date(), date) ? (
                        <Button
                            block
                            onClick={() => setNearestTimeKey(timeKeyData)}
                            icon={
                                <div style={{ display: "inline-block", paddingRight: "10px" }}>
                                    <FaShip fontSize={"12px"} color="#3c3c3c" />
                                </div>
                            }
                        >
                            <Typography.Text style={{ paddingBottom: "10px" }}>Next Ferry</Typography.Text>
                        </Button>
                    ) : (
                        <Button
                            block
                            onClick={() => setDate(new Date())}
                            icon={
                                <div style={{ display: "inline-block", paddingRight: "10px" }}>
                                    <FaCalendarDay fontSize={"12px"} color="#3c3c3c" />
                                </div>
                            }
                        >
                            <Typography.Text style={{ paddingBottom: "10px" }}>Go to Today</Typography.Text>
                        </Button>
                    )}
                    {/* <Typography.Text>{JSON.stringify(timeKeyData)}</Typography.Text> */}
                    {timeKeyData.map((time, index) => (
                        <Card
                            key={index}
                            size="small"
                            style={{
                                cursor: "pointer",
                                borderLeftColor:
                                    nowTime > time.time || isBefore(new Date(date), sub(new Date(), { days: 1 }))
                                        ? "#d9d9d9"
                                        : time.journey === "dt"
                                        ? "#91D5FF"
                                        : "#FFBB96",
                                borderLeftWidth: "6px"
                            }}
                            onClick={() => setDateTimeKey(time)}
                        >
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Row justify="space-between">
                                    <Col>
                                        <Typography.Text>{TimeUtils.getPrettyTimeAMPM(time.time)}</Typography.Text>
                                    </Col>
                                    <Col align={"end"}>
                                        <FerryTypeLabel
                                            locked={time.type === "locked" ? true : false}
                                            type={time.type}
                                            hideScheduled={true}
                                        />
                                        {dateTimeKey !== null && dateTimeKey.time === time.time ? (
                                            <SyncOutlined style={{ color: "#BDC3C7" }} />
                                        ) : (
                                            <ArrowRightOutlined style={{ color: "#BDC3C7" }} />
                                        )}
                                    </Col>
                                </Row>
                                <Space direction="horizontal" wrap>
                                    <Tag
                                        size="large"
                                        color={time.passengers >= GlobalUtils.totalPassengers ? "red" : "blue"}
                                        icon={
                                            <UserOutlined
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            />
                                        }
                                    >
                                        <Typography.Text style={{ fontSize: "1.2em", color: "inherit" }}>{time.passengers}</Typography.Text>
                                    </Tag>

                                    {time.blocks.length > 0 ? (
                                        <Tag size="large" color={"orange"} icon={<LockOutlined />}>
                                            <Typography.Text style={{ fontSize: "1.2em", color: "inherit" }}>
                                                {time.blocks.length}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {GlobalUtils.deckSpaces ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaces + time.blocks.length * 2 >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                            icon={
                                                <CarOutlined
                                                    style={{
                                                        fontSize: "1.2em",
                                                        color: "inherit"
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            >
                                                {time.cars}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {time.vans > 0 ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaces + time.blocks.length * 2 >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                        >
                                            <FaShuttleVan
                                                style={{
                                                    fontSize: "1.2em",
                                                    paddingTop: "4px",
                                                    paddingBottom: "0px",
                                                    color: "inherit"
                                                }}
                                            />
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit",
                                                    paddingLeft: "2px"
                                                }}
                                            >
                                                {time.vans}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {time.trucks > 0 ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaceTotal >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                            icon={
                                                <TbTruck
                                                    style={{
                                                        fontSize: "1.5em",
                                                        paddingTop: "2px",
                                                        paddingBottom: "2px",
                                                        color: "inherit"
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            >
                                                {time.trucks}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                    {time.bikes > 0 ? (
                                        <Tag
                                            size="large"
                                            color={time.deckSpaces + time.blocks.length * 2 >= GlobalUtils.deckSpaces ? "red" : "purple"}
                                            icon={
                                                <TbBike
                                                    style={{
                                                        fontSize: "1.5em",
                                                        paddingTop: "2px",
                                                        paddingBottom: "2px",
                                                        color: "inherit"
                                                    }}
                                                />
                                            }
                                        >
                                            <Typography.Text
                                                style={{
                                                    fontSize: "1.2em",
                                                    color: "inherit"
                                                }}
                                            >
                                                {time.bikes}
                                            </Typography.Text>
                                        </Tag>
                                    ) : (
                                        <></>
                                    )}
                                </Space>
                            </Space>
                        </Card>
                    ))}
                </Space>
            ) : (
                <V2MobileBottomSheet
                    date={date}
                    setDate={setDate}
                    dateTimeKey={dateTimeKey}
                    setDateTimeKey={(v) => setDateTimeKey(v)}
                    timeKeyData={timeKeyData}
                    setNearestTimeKey={setNearestTimeKey}
                />
            )}
        </Space>
    );
}
