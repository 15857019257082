import React, { useEffect, useState } from "react";

import { Divider, Button, Row, Col, Drawer, Space, Radio, Input, Descriptions, Form, DatePicker, message, Typography, Alert } from "antd";

import { CloseSquareOutlined, SaveOutlined } from "@ant-design/icons";

import * as Constants from "../../../utils/Constants";
import * as PassengerUtils from "../../../utils/PassengerUtils";
import * as TimeUtils from "../../../utils/TimeUtils";
import * as ToryService from "../../../services/ToryService";
import moment from "moment";
import { NewTicketFindAccount } from "./NewTicketFindAccount";
import TimeSelector from "../ferry/TimeSelector";
import { JourneyPicker } from "../../common/JourneyPicker";
import { PassengerListBuilder } from "../passengers/PassengerListBuilder";

import { maxDeckSpacesAvailable } from "../../../utils/Constants";

const validateTicket = function (ticket) {
    let error = null;

    if (ticket.name === "" || ticket.email === "") {
        error = "We need a name and email";
    }
    if (ticket.name === null || ticket.email === null) {
        error = "We need a name and email";
    }

    if (ticket.ticket.returnTicket && !ticket.ticket.openReturn) {
        if (ticket.ticket.outDate === null || ticket.ticket.returnDate === null) {
            error = "We need a out date and return date";
        }
        if (ticket.ticket.outTime === null || ticket.ticket.returnTime === null) {
            error = "We need a out date and return time";
        }
    } else {
        if (ticket.ticket.outDate === null) {
            error = "We need a out date date";
        }

        if (ticket.ticket.outTime === null) {
            error = "We need a out date time";
        }
    }

    if (ticket.passengers.length === 0) {
        error = "No passengers selected";
    }

    // JOURNEY
    // if (ticket.ticket.returnTicket !== true || ticket.ticket.returnTicket !== false) {
    //     error = "Ticket Type is wrong"
    // }
    // ORIGIN
    // console.log(ticket.ticket.outJourney);
    // if (ticket.ticket.outJourney !== "dm" || ticket.ticket.outJourney !== "dt") {
    //     error = "Journeys are wrong"
    // }
    // TIMES
    if (Array.isArray(ticket.ticket.outTime) || Array.isArray(ticket.ticket.returnTime)) {
        error = "Times aren't selected";
    }
    if (error !== null) {
        return error;
    } else {
        return true;
    }
    // return true;
};

const starterDate = new Date();

const starterTicket = {
    userid: null,
    name: null,
    email: null,
    ticket: {
        returnTicket: false,
        outJourney: "dm",
        outDate: starterDate.getTime(),
        outTime: 0,
        outReroute: false,
        returnDate: starterDate.getTime(),
        returnTime: 0,
        returnReroute: false,
        passengers: PassengerUtils.getEmptyPassengerList(),
        openReturn: false
    },
    price: 0,
    promo: null,
    method: "admin",
    status: "reserved",
    payment: {
        status: "reserved",
        provider: "admin"
    },
    passengers: [],
    meta: {
        specialreq: null,
        cardetails: null,
        number: null,
        smartpass: null
    },
    deleted: false
};

export default function NewTicketPanel(props) {
    const { onClose, width, visible } = props;

    const [newTicket, setNewTicket] = useState(starterTicket);
    const [update, setUpdate] = useState(0);
    const [outTimes, setOutTimes] = useState(null);
    const [returnTimes, setReturnTimes] = useState(null);

    function resetTicket() {
        setNewTicket(starterTicket);
        updateTicket("name", "");
        updateTicket("email", "");
        updateMetaTicket("number", "");
        updateMetaTicket("cardetails", "");
        updateMetaTicket("specialreq", "");
        // updateTicket("outDate", starterDate.getTime());
        changeJourney("dm");
        updateTicketDetails("returnTicket", true);
        onOutDateChange({ _d: new Date(starterDate.getTime()) });
        updateTicket("outTime", 0);
        // updateTicket("returnDate", starterDate.getTime());
        onReturnDateChange({ _d: new Date(starterDate.getTime()) });
        updateTicketDetails("returnTime", 0);
        updateTicket("status", "reserved");
        updateTicket("passengers", []);
        updateAccount(null);
        updateTicketDetails("passengers", {});
        setUpdate(0);
    }

    // function updatePassengerList(passenger, value) {
    //     let _ticket = newTicket;
    //     _ticket.ticket.passengers[passenger] = value;
    //     setNewTicket(_ticket);
    //     setUpdate(update + 1);
    // }

    function updateTicketReturnType(returnType) {
        // console.log(returnType);
        if (returnType === 2) {
            updateTicketDetails("openReturn", true);
            updateTicketDetails("returnTicket", true);
            updateTicketDetails("returnReroute", false);
            onReturnDateChange({ _d: new Date(starterDate.getTime()) });
            updateTicketDetails("returnTime", 0);
        }
        if (returnType === 1) {
            updateTicketDetails("returnTicket", true);
            updateTicketDetails("openReturn", false);
            onReturnDateChange({ _d: new Date(starterDate.getTime()) });
            updateTicketDetails("returnTime", 0);
        }
        if (returnType === 0) {
            updateTicketDetails("returnTicket", false);
            updateTicketDetails("openReturn", false);
        }
    }

    //
    function updatePassengers(passenger) {
        // console.log(passenger);
        // console.log(newTicket.passengers);
        let currentPassengers = newTicket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(passenger.code);
        if (matchingCodeIndex > -1) {
            currentPassengers[matchingCodeIndex].quantity += 1;
        } else {
            currentPassengers.push(passenger);
        }
        updateTicket("passengers", currentPassengers);
    }
    //
    function removePassenger(code) {
        let currentPassengers = newTicket.passengers;
        let matchingCodeIndex = currentPassengers.map((cp) => cp.code).indexOf(code);
        if (matchingCodeIndex > -1) {
            if (currentPassengers[matchingCodeIndex].quantity > 1) {
                currentPassengers[matchingCodeIndex].quantity -= 1;
            } else {
                currentPassengers.splice(matchingCodeIndex, 1);
            }
        }
        updateTicket("passengers", currentPassengers);
    }

    function updateMetaTicket(meta, value) {
        let _ticket = newTicket;
        _ticket.meta[meta] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    async function loadTicketTimes() {
        const today = new Date();
        let date = { _d: today };
        onOutDateChange(date, "");
        onReturnDateChange(date, "");
    }

    async function changeJourney(journey) {
        //
        updateTicketDetails("outJourney", journey);
        //
        let tempTicket = newTicket;
        // console.log(tempTicket.ticket.outDate)
        const outDate = TimeUtils.getDateFromEpoch(tempTicket.ticket.outDate);
        const returnDate = TimeUtils.getDateFromEpoch(tempTicket.ticket.returnDate);
        // console.log(outDate);
        // console.log(returnDate);
        onOutDateChange({ _d: outDate });
        onReturnDateChange({ _d: returnDate });

        // onReturnDateChange(returnDate);
    }

    async function onOutDateChange(date) {
        // console.log(date)
        if (date !== null) {
            // Constants.getNumericalDateString(Constants.getEpochDate(date._d))
            // updateTicket("outDate", Constants.getEpochDate(date._d))
            // setOutDate(Constants.getEpochDate(date._d));
            let tempTicket = newTicket;
            tempTicket.ticket.outDate = Constants.getEpochDate(date._d);
            // this.setState({ticket: tempTicket});
            let response = await ToryService.getDateTimes(newTicket.ticket.outJourney, Constants.getEpochDate(date._d));
            // updateTicket("outTime", response.times);
            // RESET TICKET TIMES
            tempTicket.ticket.outTime = null;
            tempTicket.ticket.outReroute = false;
            //
            setNewTicket(tempTicket);
            setOutTimes(Constants.getOneDayTimesArray(response));
            setUpdate(update + 1);
        }
    }

    async function onReturnDateChange(date) {
        if (date !== null) {
            // Constants.getNumericalDateString(Constants.getEpochDate(date._d))
            // updateTicket("outDate", Constants.getEpochDate(date._d))
            // setOutDate(Constants.getEpochDate(date._d));
            let tempTicket = newTicket;
            tempTicket.ticket.returnDate = Constants.getEpochDate(date._d);
            // this.setState({ticket: tempTicket});
            let response = await ToryService.getDateTimes(
                newTicket.ticket.outJourney === "dm" ? "dt" : "dm",
                Constants.getEpochDate(date._d)
            );
            // updateTicket("outTime", response.times);
            tempTicket.ticket.returnTime = response.times;
            tempTicket.ticket.returnReroute = false;
            // this.setState({ticket: tempTicket});
            // this.setState({ ticket: tempTicket, returnTimes: Constants.getOneDayTimesArray(response) });
            setNewTicket(tempTicket);
            setReturnTimes(Constants.getOneDayTimesArray(response));
            setUpdate(update + 1);
        }
    }

    function setOutTime(timeString, reroute) {
        // console.log(timeString)
        let tempTicket = newTicket;
        tempTicket.ticket.outTime = timeString;
        tempTicket.ticket.outReroute = reroute;
        setNewTicket(tempTicket);
        setUpdate(update + 1);
        // this.setState({ ticket: newTicket });
    }
    function setReturnTime(timeString, reroute) {
        let tempTicket = newTicket;
        tempTicket.ticket.returnTime = timeString;
        tempTicket.ticket.returnReroute = reroute;
        setNewTicket(tempTicket);
        setUpdate(update + 1);
    }

    function updateTicket(property, value) {
        // console.log(value);
        let _ticket = newTicket;
        _ticket[property] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    function updateTicketDetails(property, value) {
        let _ticket = newTicket;
        _ticket.ticket[property] = value;
        setNewTicket(_ticket);
        setUpdate(update + 1);
    }

    function updateAccount(pickedAccount) {
        // console.log(accountid);
        if (pickedAccount === null) return;
        const selectedAccount = JSON.parse(pickedAccount);
        let _ticket = newTicket;
        // let _account = accounts.find((a) => a._id === accountid)
        // console.log(_account);
        // console.log(selectedAccount);

        if (selectedAccount.accountType === "temp") {
            _ticket.userid = null;
            // GET NAME
            _ticket.name = selectedAccount.name;
            // GET EMAIL
            _ticket.email = selectedAccount.email;
            // GET NUMBER
            _ticket.meta.number = selectedAccount.number;
            _ticket.meta.cardetails = selectedAccount.cardetails;
            setNewTicket(_ticket);
            setUpdate(update + 1);
        } else {
            if (selectedAccount._id === null) {
                // console.log("clearing ticket");
                // clearTicket
                _ticket.userid = null;
                // GET NAME
                _ticket.name = "";
                // GET EMAIL
                _ticket.email = "";
                // GET NUMBER
                _ticket.meta.number = "";
                setNewTicket(_ticket);
                setUpdate(update + 1);
            } else {
                // console.log(accountid);
                _ticket.userid = selectedAccount._id;
                // GET NAME
                _ticket.name = selectedAccount.displayName;
                // GET EMAIL
                _ticket.email = selectedAccount.email;
                // GET NUMBER
                _ticket.meta.number = selectedAccount.phoneNumber;
                _ticket.meta.cardetails = selectedAccount.cardetails;
                // console.log(_ticket)
                setNewTicket(_ticket);
                setUpdate(update + 1);
            }
        }
    }

    // const [accounts, setAccounts] = useState([])

    useEffect(() => {
        // loadAccounts()
        loadTicketTimes();
        // return () => { unmounted = true };
    }, []);

    // async function loadAccounts() {
    //   let _accounts = await ToryService.getAccounts()
    //   setAccounts(_accounts.accounts)
    // }

    async function saveTicketToServer() {
        // console.log(readyTicket);
        // let ticket = this.state.ticket

        if (newTicket.email === "" || newTicket.email === null) {
            updateTicket("email", `quickticket@toryferry.com`);
        }

        // VALIDATE TICKET FIRST
        if (validateTicket(newTicket) === true) {
            let response = await ToryService.addNewTicket(newTicket);
            if (response.status === 200) {
                message.success("Ticket Added");
                resetTicket();
                onClose();
            } else {
                message.error("Ticket cannot be added to the server");
            }
        } else {
            message.error(`Ticket cannot be added to the server: ${validateTicket(newTicket)}`);
        }
    }

    function clearAccount() {
        // console.log("CLEARING");
        updateTicket("name", "");
        updateTicket("email", "");
        updateMetaTicket("number", "");
        updateMetaTicket("cardetails", "");
        updateMetaTicket("specialreq", "");
    }

    return (
        <>
            <Drawer
                title="New Ticket"
                placement="right"
                closable={true}
                closeIcon={<CloseSquareOutlined />}
                width={width}
                onClose={onClose}
                open={visible}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Descriptions layout="vertical" column={2} size="small" bordered>
                        {/* ROW */}
                        <Descriptions.Item span={2} label="Customer Details">
                            <NewTicketFindAccount clearAccount={() => clearAccount()} updateAccount={(id) => updateAccount(id)} />
                            <Form
                                labelCol={{
                                    span: 8
                                }}
                                wrapperCol={{
                                    span: 16
                                }}
                            >
                                <Form.Item label="Name">
                                    <Input
                                        placeholder="First Name Surname"
                                        onChange={(v) => updateTicket("name", v.target.value)}
                                        value={newTicket.name}
                                    />
                                </Form.Item>
                                <Form.Item label="Email">
                                    <Input
                                        placeholder="admin@toryferry.com"
                                        onChange={(v) => updateTicket("email", v.target.value)}
                                        value={newTicket.email}
                                    />
                                </Form.Item>
                                <Form.Item label="Number">
                                    <Input
                                        placeholder="+353 XXX XXXX"
                                        onChange={(v) => updateMetaTicket("number", v.target.value)}
                                        value={newTicket.meta.number}
                                    />
                                </Form.Item>
                                {maxDeckSpacesAvailable > 0 ? (
                                    <Form.Item label="Car Details">
                                        <Input
                                            placeholder="Car Type"
                                            onChange={(v) => updateMetaTicket("cardetails", v.target.value)}
                                            value={newTicket.meta.cardetails}
                                        />
                                    </Form.Item>
                                ) : (
                                    <></>
                                )}
                                <Form.Item label="Special Reqs">
                                    <Input
                                        placeholder="Wheelchair Access, Bike Rack etc"
                                        onChange={(v) => updateMetaTicket("specialreq", v.target.value)}
                                    />
                                </Form.Item>
                            </Form>
                        </Descriptions.Item>
                        {/* STATUS */}
                        <Descriptions.Item span={2} label="Status">
                            <Radio.Group
                                value={newTicket.status}
                                onChange={(e) => updateTicket("status", e.target.value)}
                                buttonStyle="solid"
                            >
                                <Radio.Button value={"reserved"}>Reserved</Radio.Button>
                                <Radio.Button value={"succeeded"}>Paid</Radio.Button>
                            </Radio.Group>
                        </Descriptions.Item>
                        {/* TICKET DETAILS */}
                        <Descriptions.Item span={2} label="Return Ticket">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Radio.Group
                                    value={newTicket.ticket.openReturn ? 2 : newTicket.ticket.returnTicket ? 1 : 0}
                                    onChange={(v) => updateTicketReturnType(v.target.value)}
                                    buttonStyle="solid"
                                >
                                    <Radio.Button value={0}>One Way</Radio.Button>
                                    <Radio.Button value={1}>Return</Radio.Button>
                                    <Radio.Button value={2}>Open Return</Radio.Button>
                                </Radio.Group>

                                {newTicket.ticket.openReturn === true ? (
                                    <Alert message={`Remind customer to hold onto ticket, for scanning later`} type="warning" />
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Journey">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <JourneyPicker
                                    journey={newTicket.ticket.outJourney}
                                    onJourneyChange={(e) => changeJourney(e.target.value)}
                                />

                                {newTicket.ticket.returnTicket ? (
                                    <Typography.Text>
                                        Return Journey:{" "}
                                        {newTicket.ticket.outJourney === "dm" ? "Departing from Tory" : "Departing Magheroarty"}
                                        {newTicket.ticket.openReturn ? ` in the future` : ``}
                                    </Typography.Text>
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Travel Dates">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Row type="flex">
                                    <Col xs={24} md={12}>
                                        <Space
                                            style={{
                                                width: "100%"
                                            }}
                                            direction="vertical"
                                        >
                                            <Typography.Title level={5}>Out Date</Typography.Title>
                                            <DatePicker
                                                defaultValue={new Date()}
                                                value={moment(
                                                    new Date(Constants.getNumericalDateString(newTicket.ticket.outDate ?? new Date()))
                                                )}
                                                onChange={onOutDateChange}
                                            />
                                            <Typography.Title level={5}>Out Time</Typography.Title>
                                            <TimeSelector time={newTicket.ticket.outTime} timeArray={outTimes} setTime={setOutTime} />
                                            {newTicket.ticket.outReroute ? <span>* REROUTED FERRY</span> : <></>}
                                        </Space>
                                    </Col>
                                    {newTicket.ticket.returnTicket && !newTicket.ticket.openReturn ? (
                                        <Col xs={24} md={12}>
                                            <Space
                                                style={{
                                                    width: "100%"
                                                }}
                                                direction="vertical"
                                            >
                                                <Typography.Title level={5}>Return Date</Typography.Title>
                                                <DatePicker
                                                    value={moment(
                                                        new Date(
                                                            Constants.getNumericalDateString(newTicket.ticket.returnDate ?? new Date())
                                                        )
                                                    )}
                                                    onChange={onReturnDateChange}
                                                />
                                                <Typography.Title level={5}>Return Time</Typography.Title>
                                                <TimeSelector
                                                    time={newTicket.ticket.returnTime}
                                                    timeArray={returnTimes}
                                                    setTime={setReturnTime}
                                                />
                                                {newTicket.ticket.returnReroute ? <span>* REROUTED FERRY</span> : <></>}
                                            </Space>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                </Row>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Passengers">
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{
                                    width: "100%"
                                }}
                            >
                                {/* <UpdatePassengerList updatePassengerList={(i, v) => updatePassengerList(i, v)} newTicket={newTicket} /> */}
                                <PassengerListBuilder
                                    updatePassengers={(t) => updatePassengers(t)}
                                    removePassenger={removePassenger}
                                    newTicket={newTicket}
                                />
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>

                    {validateTicket(newTicket) ? (
                        <Row type="flex" align="middle">
                            <Button onClick={() => resetTicket()} icon={<SaveOutlined />} type="ghost" danger>
                                Clear Ticket
                            </Button>
                            <Divider orientation="right">
                                <Button onClick={saveTicketToServer} icon={<SaveOutlined />} type="primary">
                                    Add Ticket
                                </Button>
                            </Divider>
                        </Row>
                    ) : (
                        <></>
                    )}
                </Space>

                {/* <Typography.Text>{JSON.stringify(newTicket)}</Typography.Text> */}
            </Drawer>
        </>
    );
}
