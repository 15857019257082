import React, {
  // Component 
  useContext
} from 'react';

import {
  Layout,
  Row,
  Col,
  Button,
  Result
} from 'antd';

import {
  Switch, Route, Redirect, BrowserRouter as Router,
} from "react-router-dom";

import {
  QuestionCircleOutlined
} from '@ant-design/icons';

import './css/Application.css';
// 
import Dashboard from '../pages/Dashboard';
import Timetables from '../pages/Timetables';
import Cancel from '../pages/Cancel';
import Extra from '../pages/Extra';
import Reroute from '../pages/Reroute';
import Lock from '../pages/Lock';
import Messages from '../pages/Messages';
import Settings from '../pages/Settings';
import Definitions from '../pages/Definitions';
import Notifications from '../pages/Notifications';
import GoldenTickets from '../pages/GoldenTickets';
import Tickets from '../pages/Tickets';
import Accounts from '../pages/Accounts';
import SignIn from './SignIn';
import Sidebar from './Sidebar';
import { UserContext } from '../provider/UserProvider';

import { signOutWithGoogle } from '../services/firebase';
import Prices from '../pages/Prices';

const { Content, Footer } = Layout;


function Application() {

  const user = useContext(UserContext);

  function signOut() {
    localStorage.setItem("loggedIn", "false");
    signOutWithGoogle()
  }


  return (
    user ?
      user.type === "admin" ?
        <Router>
          <Layout style={{ minHeight: "100vh" }}>

            <Sidebar />

            <Layout>
              <Content>

                <Switch>

                  <Route exact from="/" component={Dashboard} />

                  <Route exact from="/timetables" component={Timetables} />

                  <Route exact from="/cancel" component={Cancel} />

                  <Route exact from="/extra" component={Extra} />

                  <Route exact from="/reroute" component={Reroute} />
                  
                  <Route exact from="/lock" component={Lock} />

                  <Route exact from="/tickets" component={Tickets} />

                  <Route exact from="/accounts" component={Accounts} />

                  <Route exact from="/notifications" component={Notifications} />

                  <Route exact from="/messages" component={Messages} />

                  <Route exact from="/definitions" component={Definitions} />
                  
                  <Route exact from="/prices" component={Prices} />
                  
                  <Route exact from="/golden-tickets" component={GoldenTickets} />
                  {/* STATISTICs */}
                  {/* <Route exact from="/statistics" component={Statistics} /> */}

                  <Route exact from="/settings" component={Settings} />

                  <Route exact from="/login" component={RedirectToDashboard} />

                  <Route component={NotFound} />

                </Switch>
              </Content>
              <Footer style={{ textAlign: 'center' }}>Tory Ferry © {`${new Date().getFullYear()}`} | {process.env.NODE_ENV.charAt(0).toUpperCase() + process.env.NODE_ENV.slice(1)}</Footer>
            </Layout>
          </Layout>
        </Router> 
        : <NotAdmin signout={signOut} />
      : <SignIn />
  )
}

export default Application;


function RedirectToDashboard() {
  return (
    <Redirect to="/" />
  );
}

function NotFound() {
  return (
    <Row style={{ margin: '24px' }} justify="center" align="middle" >
      <Col justify="center" style={{ textAlign: 'center' }}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </Col>
    </Row>
  )
}

function NotAdmin(props) {
  return (
    <Row style={{ margin: '24px' }} justify="center" align="middle" >
      <Col justify="center" style={{ textAlign: 'center' }}>
        <QuestionCircleOutlined style={{ fontSize: '64px', paddingBottom: '20px', color: '#08c' }} />
        <h2>Admin</h2>
        <h3>You're NOT and admin user, please refer to our website.</h3>
        <Button onClick={() => window.open("https://toryferry.com")} >Website</Button>
        <br />
        <br />
        <Button onClick={() => props.signout()} >Log Out</Button>
      </Col>
    </Row>
  )
}