import * as Constants from "../utils/Constants";
import * as Firebase from "../services/firebase";
import axios from "axios";

export async function updateMasterTime(updatedTime) {
    const headers = await Firebase.getHeaders();

    return axios
        .put(`${Constants.getApiUrl()}${Constants.v2AdminTimetables}`, updatedTime, headers)
        .then((res) => {
            // console.log(res);
            // console.log(res.data);
            return res.status;
        })
        .catch((e) => {
            console.log(e);
        });
}
