import React from "react";
import {
    Tag,
    Tooltip
    // Typography,
    // Space
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Typography } from "antd";

export default function FerryTypeLabel(props) {
    const { hideScheduled, type } = props;

    return type !== "lock" ? (
        hideScheduled && (type === null || type === undefined || type == "master") ? (
            <></>
        ) : (
            <Tooltip title={`Ferry is ${type === null || type === undefined || type == "master" ? "scheduled" : type.toLowerCase()}`}>
                <Tag style={{ textTransform: "uppercase", fontSize: "0.6em" }} color={getLabelColour(type)}>
                    {type === null || type === undefined || type === "master" ? "SCHEDULED" : type.toUpperCase()}
                </Tag>
                {/* <Typography.Text>{`${type}`}</Typography.Text> */}
            </Tooltip>
        )
    ) : hideScheduled && (type === null || type === undefined || type == "master") ? (
        <></>
    ) : (
        <>
            {/* <Tag
          style={{
            textTransform: "uppercase",
            fontSize: "0.6em",
            paddingRight: "0.5em",
          }}
          color={getLabelColour(type)}
        >
          {"SCHEDULED"}
        </Tag> */}
            <Tooltip title="Ferry is locked for bookings">
                <Tag style={{ textTransform: "uppercase", fontSize: "0.6em" }} color={"orange"}>
                    <LockOutlined />
                    {` LOCKED`}
                </Tag>
            </Tooltip>
        </>
    );
}

function getLabelColour(type) {
    if (type === null || type === undefined || type === "master") {
        return "blue";
    } else {
        switch (type.toLowerCase()) {
            case "cancelled":
            case "cancel":
                return "red";
            case "reroute":
            case "rerouted":
                return "yellow";
            case "lock":
            case "locked":
                return "blue";
            default:
                return "green";
        }
    }
}
