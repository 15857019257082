import React, { useState } from "react";

import "../../../pages/css/Dashboard.css";

import { Row, Col, Typography, Button, Popconfirm, message } from "antd";

import { CarOutlined, LockOutlined } from "@ant-design/icons";
import * as GlobalUtils from "../../../utils/Global";
import * as CarUtils from "../../../utils/CarUtils";
import * as PassengerUtils from "../../../utils/PassengerUtils";
import * as BlockService from "../../../services/BlockService";
import NewTicketPanel from "../../tickets/newticket/NewTicketPanel";
import { NormalizeDate } from "../../../utils/DateUtils";
import { Space } from "antd";

export default function V2FerryOutlineContainer(props) {
    const { selectedTime, secondFerry, selectedJourney, date, currentBlocks, allTickets, reloadBlocks } = props;

    const [update, setUpdate] = useState(0);

    // NEW TICKET
    const [newTicket, setNewTicket] = useState(false);
    // const [currentBlocks, setCurrentBlocks] = useState(blocks);

    async function changeCurrentBlocks() {
        // setCurrentBlocks(blocks);
        reloadBlocks();
        setUpdate(update + 1);
    }

    const [width] = useState(window.innerWidth);

    let tickets = allTickets;

    if (secondFerry === 1) {
        // FILTER TICKETS
        //
        // SAME CODE AS API
        let deckSpaceCalculations = [];
        // LOOP THROUGH EACH TICKET TO DETERMINE SINGLE TICKETS AND THEIR DECK SPACE TOTALS
        for (let i = 0; i < tickets.length; i++) {
            const singleTicket = tickets[i];
            const singleTicketTotalDeckSpaces = CarUtils.getDeckSpaceTotal([singleTicket]);
            const singleTicketTotalPassengers = PassengerUtils.SingleTicketPassengerTotal(singleTicket);
            const cars = CarUtils.getVehicleTotal([singleTicket], 2);
            const vans = CarUtils.getVehicleTotal([singleTicket], 4);
            const trucks = CarUtils.getVehicleTotal([singleTicket], 6);
            const bikes = CarUtils.getVehicleTotal([singleTicket], 1);
            deckSpaceCalculations.push({
                _id: singleTicket._id,
                journey: "dt",
                time: 100,
                date: 0,
                type: "master",
                secondFerry: 0,
                passengers: singleTicketTotalPassengers,
                deckSpaces: singleTicketTotalDeckSpaces,
                cars: cars,
                vans: vans,
                trucks: trucks,
                bikes: bikes
            });
        }
        // SORT TICKETS DESC - HIGHEST DECKSPACE TO LOWEST
        deckSpaceCalculations.sort((a, b) => b.deckSpaces - a.deckSpaces);
        //
        // GET INDEX FOR > MAX DECK SPACES
        let deckSpaceCount = 0;
        let breakingIndex = 0;
        deckSpaceCalculations.forEach((dsc, index) => {
            // console.log(dsc)
            // console.log(`loop deck space: ${deckSpaceCount}`)
            // console.log(`loop index: ${index}`)
            // if (deckSpaceCount > config.maxDeckSpaces) {

            if (deckSpaceCount <= GlobalUtils.deckSpaces) {
                breakingIndex = index;
            }
            // else {
            // console.log(`adding: ${dsc.deckSpaces}`)
            deckSpaceCount = deckSpaceCount + dsc.deckSpaces;
        });
        // console.log(`deckspace count: ${deckSpaceCount}`);
        // console.log(`time: ${dateTime.time}`)
        // console.log(`breaking index: ${breakingIndex}`);
        // CREATE ARRAYS OF TICKETS FOR FIRST AND SECOND FERRIES
        tickets = tickets.slice(0, breakingIndex);
        // console.log(`firstFerryArray: ${firstFerryArray.length}`)
    } else if (secondFerry === 2) {
        // FILTER TICKETS
        //
        // FILTER TICKETS
        //
        // SAME CODE AS API
        let deckSpaceCalculations = [];
        // LOOP THROUGH EACH TICKET TO DETERMINE SINGLE TICKETS AND THEIR DECK SPACE TOTALS
        for (let i = 0; i < tickets.length; i++) {
            const singleTicket = tickets[i];
            const singleTicketTotalDeckSpaces = CarUtils.getDeckSpaceTotal([singleTicket]);
            const singleTicketTotalPassengers = PassengerUtils.SingleTicketPassengerTotal(singleTicket);
            const cars = CarUtils.getVehicleTotal([singleTicket], 2);
            const vans = CarUtils.getVehicleTotal([singleTicket], 4);
            const trucks = CarUtils.getVehicleTotal([singleTicket], 6);
            const bikes = CarUtils.getVehicleTotal([singleTicket], 1);
            deckSpaceCalculations.push({
                _id: singleTicket._id,
                journey: "dt",
                time: 100,
                date: 0,
                type: "master",
                secondFerry: 0,
                passengers: singleTicketTotalPassengers,
                deckSpaces: singleTicketTotalDeckSpaces,
                cars: cars,
                vans: vans,
                trucks: trucks,
                bikes: bikes
            });
        }
        // SORT TICKETS DESC - HIGHEST DECKSPACE TO LOWEST
        deckSpaceCalculations.sort((a, b) => b.deckSpaces - a.deckSpaces);
        //
        // GET INDEX FOR > MAX DECK SPACES
        let deckSpaceCount = 0;
        let breakingIndex = 0;
        deckSpaceCalculations.forEach((dsc, index) => {
            // console.log(dsc)
            // console.log(`loop deck space: ${deckSpaceCount}`)
            // console.log(`loop index: ${index}`)
            // if (deckSpaceCount > config.maxDeckSpaces) {
            // if (deckSpaceCount > GlobalUtils.deckSpaces) {
            //     breakingIndex = index;
            //     return;
            // }
            // // console.log(`adding: ${dsc.deckSpaces}`)
            // deckSpaceCount += dsc.deckSpaces;

            if (deckSpaceCount <= GlobalUtils.deckSpaces) {
                breakingIndex = index;
            }
            // else {
            // console.log(`adding: ${dsc.deckSpaces}`)
            deckSpaceCount = deckSpaceCount + dsc.deckSpaces;
        });
        tickets = tickets.slice(breakingIndex);
    }

    //
    let buttonList = [];
    let passengerTotal = PassengerUtils.TicketPassengerTotal(tickets ?? []);
    let deckSpaceTotal = CarUtils.getDeckSpaceTotal(tickets ?? []);
    let freeDeskSpaceTotal = GlobalUtils.deckSpaces - deckSpaceTotal;
    // console.log(freeDeskSpaceTotal);
    // console.log(currentBlocks.length);
    if (freeDeskSpaceTotal - currentBlocks.length < 1) {
        freeDeskSpaceTotal = 0;
    }

    if (tickets) {
        tickets.forEach((ticket) => {
            ticket.passengers.forEach((p) => {
                if (p.deckSpaces > 0) {
                    switch (p.deckSpaces) {
                        case 1:
                            [...Array(p.quantity).keys()].forEach((q) => {
                                buttonList.push({
                                    id: ticket._id,
                                    bike: 1,
                                    car: 0,
                                    van: 0,
                                    truck: 0,
                                    deckSpace: 1,
                                    available: 0,
                                    block: 0,
                                    smallAvail: 0
                                });
                            });
                            break;
                        case 2:
                            [...Array(p.quantity).keys()].forEach((q) => {
                                buttonList.push({
                                    id: ticket._id,
                                    bike: 0,
                                    car: 1,
                                    van: 0,
                                    truck: 0,
                                    deckSpace: 2,
                                    available: 0,
                                    block: 0,
                                    smallAvail: 0
                                });
                            });
                            break;
                        case 4:
                            [...Array(p.quantity).keys()].forEach((q) => {
                                buttonList.push({
                                    id: ticket._id,
                                    bike: 0,
                                    car: 0,
                                    van: 1,
                                    truck: 0,
                                    deckSpace: 4,
                                    available: 0,
                                    block: 0,
                                    smallAvail: 0
                                });
                            });
                            break;
                        case 6:
                            [...Array(p.quantity).keys()].forEach((q) => {
                                buttonList.push({
                                    id: ticket._id,
                                    bike: 0,
                                    car: 0,
                                    van: 0,
                                    truck: 1,
                                    deckSpace: 6,
                                    available: 0,
                                    block: 0,
                                    smallAvail: 0
                                });
                            });
                            break;
                        default:
                            break;
                    }
                }
            });
        });
    }

    if (currentBlocks.length > 0) {
        [...Array(currentBlocks.length).keys()].forEach((q) => {
            buttonList.push({ id: `block_${q}`, bike: 0, car: 0, van: 0, truck: 0, deckSpace: 2, available: 0, smallAvail: 0, block: 1 });
        });
    }

    let smallAvail = 0;
    let availsTotal = GlobalUtils.deckSpaces - deckSpaceTotal - currentBlocks.length * 2;

    if (availsTotal > 0) {
        if (availsTotal % 2 !== 0) {
            availsTotal = availsTotal - 1;
            // IF BUTTON LIST CONTAINS VAN OR TRUCK
            if (buttonList.filter((bl) => bl.van > 0 || bl.truck > 0).length > 0) {
                smallAvail = 1;
                [...Array(availsTotal).keys()].forEach((q) => {
                    buttonList.push({ id: "", bike: 0, car: 0, van: 0, truck: 0, deckSpace: 2, available: 1, smallAvail: 1, block: 0 });
                });
            } else {
                smallAvail = 2;
                [...Array(availsTotal).keys()].forEach((q) => {
                    buttonList.push({ id: "", bike: 0, car: 0, van: 0, truck: 0, deckSpace: 2, available: 1, smallAvail: 2, block: 0 });
                });
            }
        } else {
            [...Array(availsTotal).keys()].forEach((q) => {
                buttonList.push({ id: "", bike: 0, car: 0, van: 0, truck: 0, deckSpace: 2, available: 1, smallAvail: 0, block: 0 });
            });
        }
    }

    // console.log(tickets);
    // console.log(buttonList);
    // console.log(`deckspace ${buttonList.map((bl) => bl.deckSpace).reduce((total, inc) => (total += inc), 0)}`);
    // console.log(`available ${buttonList.map((bl) => bl.available).reduce((total, inc) => (total += inc), 0)}`);
    // console.log(`blocks ${buttonList.map((bl) => bl.block).reduce((total, inc) => (total += inc), 0)}`);
    // console.log(`cars ${buttonList.map((bl) => bl.car).reduce((total, inc) => (total += inc), 0)}`);
    // console.log(`vans ${buttonList.map((bl) => bl.van).reduce((total, inc) => (total += inc), 0)}`);
    // console.log(`trucks ${buttonList.map((bl) => bl.truck).reduce((total, inc) => (total += inc), 0)}`);
    // console.log(`bikes ${buttonList.map((bl) => bl.bike).reduce((total, inc) => (total += inc), 0)}`);

    const bikeSpan = 3;
    const carSpan = 6;
    const vanSpan = 12;
    const truckSpan = 18;
    //
    const smallAvailSpan = 4;
    const availSpan = 6;
    //
    // const smallBlockSpan = 6;
    // const blockSpan = 6;

    return (
        <Col xs={24} md={24} key={currentBlocks.length}>
            <Row justify="end">
                <Row style={{ width: "100%" }} gutter={[8, 8]} justify="end">
                    {/* <Col xs={12} gutter={[8, 16]} style={{ paddingBottom: "10px" }}>
                        <Typography.Text code>{`FERRY ${secondFerry > 0 ? secondFerry : "1"}`}</Typography.Text>
                    </Col> */}
                    <Col xs={12} gutter={[8, 16]} style={{ paddingBottom: "10px", textAlign: "end" }}>
                        <Space direction="horizontal">
                            {currentBlocks.length > 0 ? <Typography.Text code>{`BLOCKS: ${currentBlocks.length}`}</Typography.Text> : <></>}
                            <Typography.Text code>{`PASSENGERS: ${passengerTotal}`}</Typography.Text>
                        </Space>
                    </Col>
                </Row>

                <Row wrap style={{ width: "100%" }} gutter={[8, 8]}>
                    {buttonList
                        .filter((bl) => bl.bike > 0)
                        .map((bl, index) => (
                            <Col xs={bikeSpan} key={`bk${index}`}>
                                <Button
                                    style={{ width: "100%", height: "35px", paddingLeft: "0px", paddingRight: "0px" }}
                                    danger
                                    type="primary"
                                >
                                    <Typography.Text style={{ color: "white" }}>Bike</Typography.Text>
                                </Button>
                            </Col>
                        ))}
                    {buttonList
                        .filter((bl) => bl.van > 0)
                        .map((bl, index) => (
                            <Col xs={vanSpan} key={`v${index}`}>
                                <Button style={{ width: "100%", height: "35px" }} danger type="primary">
                                    <Typography.Text style={{ color: "white" }}>LWB</Typography.Text>
                                </Button>
                            </Col>
                        ))}

                    {buttonList
                        .filter((bl) => bl.car > 0)
                        .map((bl, index) => (
                            <Col xs={carSpan} key={index}>
                                <Button style={{ width: "100%", height: "35px" }} danger type="primary">
                                    {/* <Space direction="vertical" style={{width: "100%"}}> */}
                                    <Typography.Text style={{ color: "white" }}>{`Car ${index + 1}`}</Typography.Text>
                                    {/* </Space> */}
                                </Button>
                            </Col>
                        ))}
                    {currentBlocks.map((cb, index) => (
                        <CarBlock
                            blocks={currentBlocks}
                            changeBlocks={changeCurrentBlocks}
                            span={index === 0 && freeDeskSpaceTotal % 2 !== 0 ? smallAvailSpan : availSpan}
                            index={`cb${index}`}
                            key={`cb${index}`}
                        />
                    ))}
                    {availsTotal > 0 ? (
                        [...Array(availsTotal / 2).keys()].map((frees, index) => (
                            <CarAvailable
                                setNew={() => setNewTicket(true)}
                                ferry={{ date: date, time: selectedTime, journey: selectedJourney }}
                                blocks={currentBlocks}
                                changeBlocks={changeCurrentBlocks}
                                update={() => setUpdate(update + 1)}
                                span={availSpan}
                                index={`avail${index}`}
                                key={`avail${index}`}
                            />
                        ))
                    ) : (
                        <></>
                    )}
                    {smallAvail === 1 && availsTotal > 0 ? (
                        <CarAvailable
                            setNew={() => setNewTicket(true)}
                            ferry={{ date: date, time: selectedTime, journey: selectedJourney }}
                            blocks={currentBlocks}
                            changeBlocks={changeCurrentBlocks}
                            update={() => setUpdate(update + 1)}
                            span={smallAvailSpan}
                            index={"availSmall"}
                        />
                    ) : (
                        <></>
                    )}
                    {buttonList
                        .filter((bl) => bl.truck > 0)
                        .map((bl, index) => (
                            <Col xs={truckSpan} key={`bl${index}`}>
                                <Button style={{ width: "100%", height: "35px" }} danger type="primary">
                                    <Typography.Text style={{ color: "white" }}>Lorry</Typography.Text>
                                </Button>
                            </Col>
                        ))}
                    {/* <Space direction="vertical">
                        <Typography.Text>{`free deck space ${availsTotal}`}</Typography.Text>
                        <Typography.Text>{`small deck space ${smallAvail}`}</Typography.Text>
                        <Typography.Text>{`blocks ${currentBlocks.length}`}</Typography.Text>
                    </Space> */}
                </Row>
            </Row>
            <br />

            <NewTicketPanel onClose={() => setNewTicket(false)} visible={newTicket} width={width > 900 ? 640 : width} />
        </Col>
    );
}

export function CarAvailable(props) {
    const { setNew, ferry, blocks, changeBlocks, update, span, index } = props;

    async function BlockCar() {
        // console.log(ferry)
        let response = await BlockService.addBlock({
            date: NormalizeDate(ferry.date),
            time: ferry.time,
            journey: ferry.journey
        });
        // console.log(response);
        if (response.status === 200) {
            message.success("Blocked Successfully");
            const _blocks = blocks;
            _blocks.push({ _id: response.block });
            // console.log(_blocks);
            changeBlocks(_blocks);
            update();
        } else {
            message.error("Can't Block this car");
        }
    }

    return (
        <Col xs={span} key={index}>
            <Popconfirm
                title="Do you want to block or add ticket"
                icon={<CarOutlined />}
                onConfirm={() => setNew()}
                onCancel={() => BlockCar()}
                okText="New Ticket"
                cancelText="Block"
            >
                <Button style={{ width: "100%", height: "35px", paddingLeft: "0px", paddingRight: "0px" }}>
                    <Typography.Text>Available</Typography.Text>
                </Button>
            </Popconfirm>
        </Col>
    );
}

export function CarBlock(props) {
    const { blocks, changeBlocks, span, index } = props;

    async function unBlockCar() {
        // console.log(blocks)
        let response = await BlockService.removeBlock(blocks[0]._id);
        if (response.status === 200) {
            message.success("Blocked Removed Successfully");
            const _blocks = blocks.filter((b) => blocks[0]._id !== b._id);
            changeBlocks(_blocks);
        } else {
            message.error("Can't unblock this car");
        }
    }

    return (
        <Col xs={span} key={index}>
            {/* <Button style={{ width: "100%", height: "35px" }} danger icon={<LockOutlined color="yellow" />}>
                <Typography.Text>{`Block`}</Typography.Text>
            </Button> */}
            <Popconfirm
                title="Do you want to remove the block?"
                icon={<CarOutlined />}
                onConfirm={() => unBlockCar()}
                onCancel={() => message.error("Cancelled")}
                okText="Unblock"
                cancelText="Cancel"
            >
                <Button
                    style={{ width: "100%", height: "35px", paddingLeft: "0px", paddingRight: "0px" }}
                    danger
                    icon={<LockOutlined color="yellow" />}
                >
                    {`Block`}
                </Button>
            </Popconfirm>
        </Col>
    );
}
