import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import * as ToryService from './ToryService';

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: "AIzaSyDqD1igFejKgrY3TW6TYcrVadOOo5Ec9pA",
	authDomain: "tory-ferry.firebaseapp.com",
	databaseURL: "https://tory-ferry.firebaseio.com",
	projectId: "tory-ferry",
	storageBucket: "tory-ferry.appspot.com",
	messagingSenderId: "361266958764",
	appId: "1:361266958764:web:a394677ed9b927a3d32a09"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
// export const firestore = firebase.firestore();


const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
	auth.signInWithPopup(provider);
};

export const signInWithEmail = async (email, password) => {

	// const emailValid = await ToryService.checkEmail(email);
	// console.log(emailValid);

	// if (emailValid) {
	// SIGN IN
	auth.signInWithEmailAndPassword(email, password)
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(`${errorCode} ${errorMessage}`)
		});
	// } else {
	// 	// REGISTER
	// 	auth().createUserWithEmailAndPassword(email, password)
	// 		.catch((error) => {
	// 			const errorCode = error.code;
	// 			const errorMessage = error.message;
	// 		});
	// }
}

export const signOutWithGoogle = () => {
	console.log("signout");
	// auth.signInWithPopup(provider);
	auth.signOut();
};

export const sanitizeUser = (user, token, provider) => {
	return {
		// key: user.uid,
		uid: user.uid,
		displayName: user.displayName,
		email: user.email,
		phoneNumber: user.phoneNumber,
		photoURL: user.photoURL,
		type: "user",
		token: token,
		provider: provider
	}
}

export const getHeaders = async () => {
	const user = auth.currentUser;

	let newtoken = await user.getIdToken();

	return { "headers": { "authtoken": newtoken, "uid": user.uid } };
}

export const getUsername = async () => {
	const user = auth.currentUser;
	// console.log(user);
	return { "username": user };
}

export const generateUserDocument = async (user, additionalData) => {
	if (!user) return;

	let authToken = await user.getIdToken();
	// console.log(authToken);

	// console.log(sanitizeUser(user, null, "google"));

	try {
		await ToryService.createUser(authToken, sanitizeUser(user, null, "google"))
	} catch (e) {
		console.log(e);
	}

	return getUserDocument(user);
	// const userRef = firestore.doc(`users/${user.uid}`);
	// const snapshot = await userRef.get();
	// const snapshot = {};

	// if (!snapshot.exists) {
	// 	const { email, displayName, photoURL } = user;
	// 	try {
	// 		//   await userRef.set({
	// 		//     displayName,
	// 		//     email,
	// 		//     photoURL,
	// 		//     ...additionalData
	// 		//   });
	// 		// TORY SERVICE CREATE USER
	// 		console.log(user);
	// 	} catch (error) {
	// 		console.error("Error creating user document", error);
	// 	}
	// }
	// return getUserDocument(user.uid);
};

const getUserDocument = async user => {
	if (!user) return null;
	try {
		let authToken = await user.getIdToken();

		// RETURN USER
		let toryUser = await ToryService.getUser(authToken, user.uid);

		if (toryUser !== null) {
			localStorage.setItem("loggedIn", "true");
		}

		// console.log(toryUser.data.user._id);
		return toryUser.data.user;

	} catch (error) {
		console.error("Error fetching user", error);
	}
};