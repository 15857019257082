export function checkOnlyContainsDaily(only, days) {
  let contained = false
  if (only) {
    only.forEach((element) => {
      if (JSON.stringify(element) === JSON.stringify(days)) {
        contained = true
      }
    })
  }
  return contained
}

export function checkOnlyIndex(only, days) {
  // console.log(only.indexOf(days));
  // console.log(`${only} ${days}`)
  let index = -1;
  only.forEach((o,i) => {
    if(JSON.stringify(o) === JSON.stringify(days)){
      index = i;
    }
  })
  // console.log(only)
  // console.log(days);
  return index;
}
