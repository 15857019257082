import React, { useState, useEffect } from "react";
// import axios from 'axios';
// CSS
// import './Timetables.css';

import {
    Divider,
    PageHeader,
    Button,
    Row,
    Col,
    Input,
    Popconfirm,
    Card,
    Table,
    DatePicker,
    TimePicker,
    message,
    Space,
    Typography,
    Checkbox,
    Form,
    Radio
} from "antd";

import moment from "moment";

import {
    CloseCircleOutlined,
    UpSquareOutlined,
    DownSquareOutlined,
    SaveOutlined
} from "@ant-design/icons";

import * as Constants from "../utils/Constants";
import * as ToryService from "../services/ToryService";
import * as MessageService from "../services/MessageService";
import LogoutButton from "../components/LogoutButton";
import { CardRightAlignedAction } from "../components/common/CardRightAlignedAction";
import { format } from "date-fns";

const datetimeFormat = "HH:mm";

function Messages() {
    // EXTRA
    const [messages, setMessages] = useState([]);
    // SETTING NEW ONES
    const [msg, setMessage] = useState("");
    const [url, setUrl] = useState("");
    const [color, setColor] = useState("#F56900");
    const [chosenDate, setChosenDate] = useState(new Date());
    const [time, setTime] = useState(0);
    // ARCHIVE
    const [showArchive, setShowArchive] = useState(false);
    const [archiveFerries, setArchiveFerries] = useState([]);

    const [sendNotification, setSendNotification] = useState(false);
    // const [showAdd, setAdd] = useState(false);

    function resetForm() {
        setMessage("");
        setChosenDate(new Date());
        setTime(0);
    }

    async function loadTables() {
        let response = await ToryService.getMessages();
        // console.log(response);
        setMessages(response);
    }

    async function toggleArchive() {
        // console.log(msg);
        // DO POST
        let archiveFerries = await ToryService.getMessageArchive();
        // console.log(archiveFerries);
        setShowArchive(!showArchive);
        setArchiveFerries(archiveFerries);
    }

    async function messageAdd() {
        if (sendNotification) {
            // console.log("SEND NOTIFICATION");
            if (Constants.debug) {
                // console.log("SEND DEBUG NOTIFICATION");
                ToryService.addNotification(
                    "Arranmore Ferry Update",
                    msg,
                    "dev"
                );
            } else {
                // console.log("SEND ACTUAL NOTIFICATION");
                ToryService.addNotification(
                    "Arranmore Ferry Update",
                    msg,
                    "news"
                );
            }
        }

        // console.log(time);
        // console.log(chosenDate);
        let result = await MessageService.postNewMessage(
            msg,
            url,
            color,
            chosenDate,
            Constants.getNumberFromTimeString(time)
        );
        // console.log(result);
        if (result.status === 200) {
            message.success("Message Added");
            resetForm();
            loadTables();
        } else {
            message.error("Couldn't Add Message");
        }
    }

    async function removeMessage(id) {
        // console.log(id);
        let result = await ToryService.removeMessage(id);
        if (result === 200) {
            message.success("Message Removed");
            resetForm();
            loadTables();
        } else {
            message.error("Couldn't remove message");
        }
    }

    async function editMessage(id) {
        console.log("Need to implement editing " + id);
        // let result = await ToryService.removeMessage(id);
        // if (result === 200) {
        // 	message.success('Ferry Removed');
        // 	resetForm()
        // 	loadTables()
        // } else {
        // 	message.error("Couldn't remove extra Ferry");
        // }
    }

    useEffect(() => {
        // Your code here
        loadTables();
        // console.log("[] effect run");
    }, []);

    function changeDate(date, dateString) {
        if (date !== null) {
            // console.log("non null date");
            // console.log(Constants.getEpochDate(date._d));
            setChosenDate(Constants.getEpochDate(date._d));
            // loadTimes();
        }
    }
    function selectTime(time, timeString) {
        setTime(timeString);
    }

    // async function loadTimes() {
    // 	console.log(chosenDate)
    // 	if (chosenDate !== 0) {
    // 		console.log(journey);
    // 		console.log(chosenDate)
    // 		let result = await ToryService.getCancelTimes(journey, chosenDate)
    // 		setTimes(result.times)
    // 	} else {
    // 		setTimes([])
    // 	}
    // }

    return (
        <>
            <PageHeader
                ghost={false}
                title="App Messages"
                extra={<LogoutButton />}
            ></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col className="gutter-row" xs={24} md={12} lg={8}>
                        <Card
                            title="Add Message To Top of App"
                            bordered={false}
                            actions={[
                                <CardRightAlignedAction>
                                    {/* <Button type="primary" icon={<CodeOutlined />} onClick={() => findByCode()}>Find Ticket</Button> */}
                                    <Button
                                        onClick={messageAdd}
                                        disabled={
                                            !(chosenDate !== 0 && time !== 0)
                                        }
                                        icon={<SaveOutlined />}
                                        type="primary"
                                    >
                                        Add
                                    </Button>
                                </CardRightAlignedAction>
                            ]}
                        >
                            <Form layout="vertical">
                                <Space
                                    direction="vertical"
                                    style={{ width: "100%" }}
                                    // size="large"
                                >
                                    <Form.Item label="Message" required>
                                        <Input.TextArea
                                            value={msg}
                                            placeholder="The message to appear in app"
                                            onChange={(v) =>
                                                setMessage(v.target.value)
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="URL"
                                        rules={[
                                            { type: "url", warningOnly: true }
                                        ]}
                                    >
                                        <Input
                                            value={url}
                                            placeholder={"https://..."}
                                            onChange={(v) =>
                                                setUrl(v.target.value)
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item label="Color">
                                        <Space direction="horizontal">
                                            <Radio.Group
                                                onChange={(v) =>
                                                    setColor(v.target.value)
                                                }
                                                value={color}
                                                options={[
                                                    {
                                                        label: "Orange",
                                                        value: "#F56900"
                                                    },
                                                    {
                                                        label: "Blue",
                                                        value: "#0D47A1"
                                                    },
                                                    {
                                                        label: "Red",
                                                        value: "#C62828"
                                                    }
                                                ]}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </Space>
                                    </Form.Item>
                                    <Form.Item label="Expiry Date">
                                        <DatePicker
                                            value={new moment(chosenDate)}
                                            onChange={changeDate}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Expiry Time">
                                        <TimePicker
                                            defaultValue={moment(
                                                time,
                                                datetimeFormat
                                            )}
                                            onChange={selectTime}
                                            format={datetimeFormat}
                                        />
                                    </Form.Item>
                                    <Checkbox
                                        onChange={(v) =>
                                            setSendNotification(
                                                !sendNotification
                                            )
                                        }
                                        checked={sendNotification}
                                    >
                                        Send as News Notification{" "}
                                    </Checkbox>
                                </Space>
                            </Form>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12} lg={16}>
                        <Card title="Message Status" bordered={false}>
                            <MessageTable
                                messages={messages}
                                removeMessage={removeMessage}
                                editMessage={editMessage}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>

            <div style={{ margin: "24px 16px 0" }}>
                <Divider orientation="left">
                    <Button
                        icon={
                            showArchive ? (
                                <UpSquareOutlined />
                            ) : (
                                <DownSquareOutlined />
                            )
                        }
                        onClick={toggleArchive}
                        type="outline"
                        color="secondary"
                    >
                        {showArchive ? "Hide" : "Show"} Archive{" "}
                    </Button>
                </Divider>
                {showArchive ? (
                    <ArchiveTable archiveFerries={archiveFerries} />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default Messages;

const MessageTable = (props) => {
    let data = [];

    props.messages.map((i, index) => {
        let j = i;
        j.key = i._id;
        return data.push(j);
    });

    data.sort((a, b) => b.date - a.date);

    const columns = [
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            render: (value, row) => <Typography.Text>{value}</Typography.Text>
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
            render: (value, row) =>
                value === undefined ? (
                    <Typography.Text>No color</Typography.Text>
                ) : (
                    <div
                        style={{
                            backgroundColor: value,
                            width: "20px",
                            height: "20px"
                        }}
                    ></div>
                )
        },
        {
            title: "Link",
            dataIndex: "url",
            key: "link",
            render: (value) =>
                value === "" || value === null || value === undefined ? (
                    <Typography.Text>No Link</Typography.Text>
                ) : (
                    <a href={value} target="_blank">
                        Link
                    </a>
                )
        },
        {
            title: "Expiry Date",
            // dataIndex: "date",÷
            key: "date",
            render: (value, row) => (
                <Typography.Text>{`${Constants.getDateString(
                    row.date
                )} at ${Constants.getTimestamp(row.time)}`}</Typography.Text>
            )
        },
        // {
        //     title: "Created",
        //     dataIndex: "createdOn",
        //     key: "createdOn",
        //     sorter: (a, b) => a.createdOn - b.createdOn,
        //     render: (value) => (
        //         <Typography.Text>
        //             {format(new Date(value), "do, MMM yyy HH:mm")}
        //         </Typography.Text>
        //     )
        // },
        {
            title: "Action",
            dataIndex: "_id",
            key: "_id",
            render: (value) => (
                <>
                    {/* <Button onClick={() => props.editMessage(value)} className={"pr-1"} type="link">Edit</Button> */}
                    <Popconfirm
                        title="Are you sure archive this message?"
                        okText="Yes"
                        onConfirm={() => props.removeMessage(value)}
                        onCancel={Constants.cancel}
                        cancelText="No"
                    >
                        <Button danger>
                            {" "}
                            Archive{" "}
                            <CloseCircleOutlined twoToneColor="#ff4d4f" />
                        </Button>
                    </Popconfirm>
                </>
            )
        }
    ];

    // const journey = Constants.getJourneyString(props.journey)
    // console.log(data);

    return <Table dataSource={data} columns={columns} pagination={false} />;
};

const ArchiveTable = (props) => {
    // console.log(props.archiveFerries);

    let archiveData = [];

    props.archiveFerries.map((i) => {
        let j = i;
        j.key = i._id;
        return archiveData.push(j);
    });

    // console.log(archiveData);

    archiveData.sort((a, b) => {
        return b.createdOn - a.createdOn;
    });

    const columns = [
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            render: (value, row) => <Typography.Text>{value}</Typography.Text>
        },
        {
            title: "Color",
            dataIndex: "color",
            key: "color",
            render: (value, row) =>
                value === undefined ? (
                    <Typography.Text>No color</Typography.Text>
                ) : (
                    <div
                        style={{
                            backgroundColor: value,
                            width: "20px",
                            height: "20px"
                        }}
                    ></div>
                )
        },
        {
            title: "Link",
            dataIndex: "url",
            key: "link",
            render: (value) =>
                value === "" || value === null || value === undefined ? (
                    <Typography.Text>No Link</Typography.Text>
                ) : (
                    <a href={value} target="_blank">
                        Link
                    </a>
                )
        },
        {
            title: "Expiry Date",
            // dataIndex: "date",÷
            key: "date",
            render: (value, row) => (
                <Typography.Text>{`${Constants.getDateString(
                    row.date
                )} at ${Constants.getTimestamp(row.time)}`}</Typography.Text>
            )
        }
    ];

    return (
        <>
            <Table
                dataSource={archiveData}
                columns={columns}
                pagination={false}
            />
        </>
    );
};
