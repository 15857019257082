import React, { useState, useEffect } from "react";

import {
  Divider,
  PageHeader,
  Button,
  Row,
  Col,
  Popconfirm,
  Card,
  Table,
  DatePicker,
  Space,
  TimePicker,
  message,
  Typography,
} from "antd";

import moment from "moment";

import {
  CloseCircleOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import * as Constants from "../utils/Constants";
import * as ToryService from "../services/ToryService";
import LogoutButton from "../components/LogoutButton";
import { JourneyPicker } from '../components/common/JourneyPicker'
import { CardRightAlignedAction } from "../components/common/CardRightAlignedAction";

const format = "HH:mm";

function Extra() {
  // EXTRA
  const [ferries, setFerries] = useState({ dt: [], dm: [] });
  // SETTING NEW ONES
  const [journey, setJourney] = useState("dm");
  const [chosenDate, setChosenDate] = useState(new Date());
  const [time, setTime] = useState(0);
  // ARCHIVE
  const [showArchive, setShowArchive] = useState(false);
  const [archiveFerries, setArchiveFerries] = useState({ dt: [], dm: [] });
  // const [showAdd, setAdd] = useState(false);

  function resetForm() {
    setJourney("dm");
    setChosenDate(new Date());
    setTime(0);
  }

  async function loadTables() {
    let response = await ToryService.getExtra();
    console.log(response);
    setFerries(response);
  }

  async function toggleArchive() {
    // DO POST
    let archiveFerries = await ToryService.getExtraArchive();
    // console.log(archiveFerries);
    setShowArchive(!showArchive);
    setArchiveFerries(archiveFerries);
  }

  async function extraFerryAdd() {
    // console.log(time);
    // console.log(chosenDate);
    let result = await ToryService.addExtraFerry(
      journey,
      chosenDate.getTime(),
      Constants.getNumberFromTimeString(time)
    );
    // console.log(result);
    if (result === "OK") {
      message.success("Extra Ferry Added");
      resetForm();
      loadTables();
    } else {
      message.error("Couldn't Add Extra Ferry");
    }
  }

  async function removeExtra(id) {
    console.log(id);
    let result = await ToryService.removeExtra(id);
    if (result === 200) {
      message.success("Ferry Removed");
      resetForm();
      loadTables();
    } else {
      message.error("Couldn't remove extra Ferry");
    }
  }

  useEffect(() => {
    // Your code here
    loadTables();
    // console.log("[] effect run");
  }, []);

  function changeJourney(journey) {
    setJourney(journey);
    // loadTimes()
  }
  function changeDate(date, dateString) {
    if (date !== null) {
      // console.log("non null date");
      //   console.log(Constants.getEpochDate(date._d));
      setChosenDate(new Date(Constants.getEpochDate(date._d)));
      // loadTimes();
    }
  }
  function selectTime(time, timeString) {
    setTime(timeString);
  }

  return (
    <>
      <PageHeader
        ghost={false}
        title="Extra"
        extra={<LogoutButton />}
      ></PageHeader>

      <div style={{ margin: "8px 16px 0" }}>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
          <Col className="gutter-row" xs={24} md={12}>
            <Card title="Add Ferry" bordered={false} actions={[
              <CardRightAlignedAction>
                <Button
                  onClick={extraFerryAdd}
                  icon={<SaveOutlined />}
                  type="primary"
                  disabled={!(chosenDate !== 0 && time !== 0)}
                >
                  Add
                </Button>
              </CardRightAlignedAction>
            ]}>
              <Space direction="vertical" style={{ maxWidth: "100%", width: "100%" }}>
                <Typography.Title level={5}>Journey</Typography.Title>

                <JourneyPicker journey={journey} onJourneyChange={(e) => changeJourney(e.target.value)} />

                {/* <Radio.Group
                  value={journey}
                  onChange={(e) => changeJourney(e.target.value)}
                  className={"pb-1"}
                  buttonStyle="solid"
                >
                  <Radio.Button value="dm">Departing Magheroarty</Radio.Button>
                  <Radio.Button value="dt">Departing Tory Island</Radio.Button>
                </Radio.Group> */}
                <Typography.Title level={5}>Date</Typography.Title>
                <DatePicker
                  value={new moment(chosenDate)}
                  onChange={changeDate}
                />
                <Typography.Title level={5}>Times</Typography.Title>
                <TimePicker
                  defaultValue={moment(time, format)}
                  onChange={selectTime}
                  format={format}
                />
              </Space>
            </Card>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Card title="Extra Ferries" bordered={false}>
              <ExtraTables ferries={ferries} removeExtra={removeExtra} />
            </Card>
          </Col>
        </Row>
      </div>

      <div style={{ margin: "24px 16px 0" }}>
        <Divider orientation="left">
          <Button
            icon={showArchive ? <UpSquareOutlined /> : <DownSquareOutlined />}
            onClick={toggleArchive}
            type="outline"
            color="secondary"
          >
            {showArchive ? "Hide" : "Show"} Archive{" "}
          </Button>
        </Divider>
        {showArchive ? <ArchiveTable archiveFerries={archiveFerries} /> : <></>}
      </div>
    </>
  );
}

export default Extra;

const ExtraTables = (props) => {
  return (
    <Space direction="vertical" style={{ maxWidth: "100%", width: "100%" }} size="large">
      <ExtraTable
        journey="dm"
        ferries={props.ferries.dm}
        removeExtra={props.removeExtra}
      />
      <ExtraTable
        journey="dt"
        ferries={props.ferries.dt}
        removeExtra={props.removeExtra}
      />
    </Space>
  );
};

const ExtraTable = (props) => {
  // console.log(props.ferries);

  let data = [];

  props.ferries.map((i, index) => {
    let j = i;
    j.key = i.id;
    return data.push(j);
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => <span>{Constants.getDateString(value)}</span>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (value) => <span>{Constants.getTimestamp(value)}</span>,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (value) => (
        <>
          <Popconfirm
            title="Are you sure remove this extra ferry?"
            okText="Yes"
            onConfirm={() => props.removeExtra(value)}
            onCancel={Constants.cancel}
            cancelText="No"
          >
            <Button size="small" danger>
              {" "}
              Remove <CloseCircleOutlined twoToneColor="#ff4d4f" />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const journey = Constants.getJourneyString(props.journey);

  return (
    <Space direction="vertical" style={{ maxWidth: "100%", width: "100%" }} size="small">
      <Typography.Title level={5}> {journey}</Typography.Title>
      <Table
        size="small"
        dataSource={data}
        columns={columns}
        pagination={data.length > 25 ? true : false}
      />
    </Space>
  );
};

const ArchiveTable = (props) => {
  console.log(props.archiveFerries);

  let archiveData = [];

  props.archiveFerries.dm.map((i, index) => {
    let j = i;
    j.key = i.id;
    j.journey = "dm";
    return archiveData.push(j);
  });
  props.archiveFerries.dt.map((i, index) => {
    let j = i;
    j.journey = "dt";
    j.key = i.id;
    return archiveData.push(j);
  });

  // console.log(archiveData);

  archiveData.sort((a, b) => {
    return a.time - b.time;
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (value) => (
        <span>{Constants.getIDString(value).toUpperCase()}</span>
      ),
    },
    {
      title: "Journey",
      dataIndex: "journey",
      key: "journey",
      render: (value) => <span>{Constants.getJourneyString(value)}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => <span>{Constants.getDateString(value)}</span>,
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (value) => <span>{Constants.getTimestamp(value)}</span>,
    },
  ];

  return (
    <>
      <Table dataSource={archiveData} columns={columns} pagination={false} />
    </>
  );
};
