
import * as Constants from '../utils/Constants';
import * as Firebase from "../services/firebase"
import axios from 'axios';

export async function getPhone() {
    const headers = await Firebase.getHeaders();

    return await axios.get(Constants.getApiUrl() + Constants.settingsPhone, headers)
        .then(res => {
            // console.log(res.data.ferries);
            return res.data;
        })
        .catch(err => {
            console.log(err);
            return err;
        })
}


export async function setPhone(number) {
    const headers = await Firebase.getHeaders();

    return await axios.post(Constants.getApiUrl() + Constants.settingsPhone, { number }, headers)
        .then(res => {
            // console.log(res);
            // console.log(res.data);
            return res.data;
        }).catch((e) => {
            console.log(e);
            return e;
        })
}
