import React from "react";
// CSS
import "../../pages/css/Timetables.css";

import { Divider, Button, Row, Col, Space, Typography } from "antd";

import { PlusCircleOutlined } from "@ant-design/icons";

import { LabelDeleteButtons } from "./LabelDeleteButtons";
import { MonthlyTable } from "./MonthlyTable";
// import { checkOnlyContainsDaily } from '../../utils/DefinitionUtils';

export function MonthlyMasterTimetable(props) {
    const { definitions, times, showDrawer, loadTables, updateFerry } = props;

    return (
        <>
            {/* <Typography.Text>{`${JSON.stringify(definitions)}`}</Typography.Text> */}
            <Row type="flex">
                <Col xs={24} md={12}>
                    <Space direction="vertical" style={{ width: "100%", maxWidth: "100%" }}>
                        <Typography.Title level={4} className={"pb-1"}>
                            {`${definitions.monthLabel}`}
                        </Typography.Title>
                        <Typography.Title level={5} className={"pb-1"}>
                            Departing Magheroarty
                        </Typography.Title>

                        <LabelDeleteButtons
                            journey={"dm"}
                            definitions={definitions}
                            times={times}
                            showDrawer={showDrawer}
                            loadTables={loadTables}
                            updateFerry={updateFerry}
                        />

                        <Typography.Title level={5} className={"pb-1 pt-1"}>
                            Departing Tory Island
                        </Typography.Title>

                        <LabelDeleteButtons
                            journey={"dt"}
                            definitions={definitions}
                            times={times}
                            showDrawer={showDrawer}
                            loadTables={loadTables}
                            updateFerry={updateFerry}
                        />

                        <div style={{ margin: "24px 16px 0" }}>
                            <Divider orientation="right">
                                <Button
                                    onClick={() => showDrawer({ months: definitions.monthDefinitions, days: [], journey: "dm" })}
                                    icon={<PlusCircleOutlined />}
                                    type="primary"
                                >
                                    {`Add New`}
                                </Button>
                            </Divider>
                        </div>
                    </Space>
                </Col>

                <Col xs={24} md={12}>
                    <Space style={{ maxWidth: "100%", width: "100%" }} direction="vertical" size="large">
                        <MonthlyTable
                            journey="dm"
                            months={definitions.monthDefinitions}
                            days={definitions.dayDefinitions}
                            only={definitions.onlyDefinitions ?? []}
                            times={times}
                        />
                        <MonthlyTable
                            journey="dt"
                            months={definitions.monthDefinitions}
                            days={definitions.dayDefinitions}
                            only={definitions.onlyDefinitions ?? []}
                            times={times}
                        />
                    </Space>
                </Col>
            </Row>
        </>
    );
}
