import React, { useState, useEffect } from 'react';
import {
    Drawer,
    Typography,
    Button,
    Space,
    DatePicker,
    Card,
    // TimePicker,
    Divider,
    message
} from "antd";
import {
    CloseSquareOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

import { JourneyPicker } from '../../components/common/JourneyPicker'
import * as Constants from "../../utils/Constants";

import moment from "moment";
import ViewPanel from '../tickets/ViewPanel';
import SmallTicketsTable from '../tickets/SmallTicketsTable';
import CancelMoveButtons from './CancelMoveButtons';
import { CardRightAlignedAction } from '../common/CardRightAlignedAction';
import * as DateUtils from "../../utils/DateUtils"
import * as ToryService from "../../services/ToryService";

export function CancelMovePanel(props) {
    const { cancelJourney, cancelDate, cancelTime, cancelTickets, confirmedMove } = props;

    const [width] = useState(window.innerWidth);
    // MOVE
    const [move, setMove] = useState(false);
    const [moveJourney, setMoveJourney] = useState(cancelJourney);
    const [moveDate, setMoveDate] = useState(new Date())
    const [moveTime, setMoveTime] = useState(null)
    const [moveTimes, setMoveTimes] = useState([])
    // 
    const [viewTicket, setViewTicket] = useState(null);

    function resetCancelMove() {
        setMoveDate(new Date())
        setMoveTime(null)
        setMoveTimes([])
    }

    useEffect(() => {
        // console.log("useEffect only for time");
        loadTimes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveDate, moveJourney]);


    async function loadTimes() {
        // console.log(chosenDate)
        if (moveDate !== 0) {
            // console.log(journey);
            // console.log(chosenDate)
            let result = await ToryService.getDateTimes(moveJourney, moveDate);
            // console.log(result);
            setMoveTimes(Constants.getOneDayTimesArray(result));
        } else {
            setMoveTimes([]);
        }
    }

    function changeDate(date, dateString) {
        if (date !== null) {
            // console.log("non null date");
            //   console.log(Constants.getEpochDate(date._d));
            setMoveDate(new Date(Constants.getEpochDate(date._d)));
            // loadTimes();
        }
    }

    async function cancelAndMove() {
        // console.log(cancelTickets);
        const cancelTicketIds = cancelTickets.map((c) => c._id)
        // console.log(cancelTicketIds);
        // MOVE DATE, JOURNEY, TIME
        // CANCEL JOURNEY, DATE, TIME
        const body = {
            moveJourney,
            moveDate: DateUtils.NormalizeDate(moveDate).getTime(),
            moveTime,
            cancelJourney,
            cancelDate: DateUtils.NormalizeDate(cancelDate).getTime(),
            cancelTime,
            tickets: cancelTicketIds
        }

        let response = await ToryService.confirmCancelMoveFerry(body)

        if (response.status === 200) {
            message.success(`Cancelled ferry and moved tickets to ${Constants.getTimestamp(moveTime)}`)
            setViewTicket(null);
            setMove(false);
            resetCancelMove()
            confirmedMove(response.cancel)
        } else {
            message.error("Couldn't cancel and move those tickets")
        }

    }


    return <>
        {cancelTickets.length > 0
            ? <Space direction="vertical" style={{ width: "100%" }}>
                {/* <Typography.Text>Move tickets to another ferry, cancel tickets and send update email.</Typography.Text> */}
                <Button type="primary" onClick={() => setMove(true)}>Move Tickets</Button>
            </Space>
            : <></>}
        <Drawer
            title="Move and Canel Tickets"
            placement="right"
            closable={true}
            closeIcon={<CloseSquareOutlined />}
            width={width > 900 ? 640 : width}
            onClose={() => {
                setMove(false)
            }}
            open={move} >
            {/* JOURNEY,DATE,TIME, NEW FERRY */}
            <Space direction="vertical" size={"small"} style={{ width: "100%" }}>

                <Card title="Move Tickets" size='small' actions={[
                    <CardRightAlignedAction  >
                        <Button danger disabled={moveTime == null} icon={<CloseCircleOutlined />} onClick={() => cancelAndMove()}>Move Tickets and Cancel</Button>
                    </CardRightAlignedAction>
                ]}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Typography.Text>{`You are moving ${cancelTickets.length} tickets to another ferry, please see details below.`}</Typography.Text>
                        <JourneyPicker journey={moveJourney} onJourneyChange={(e) => setMoveJourney(e.target.value)} />
                        <Typography.Title level={5}>Date</Typography.Title>
                        <DatePicker
                            value={new moment(moveDate)}
                            onChange={changeDate}
                        />
                        <Typography.Title level={5}>Times</Typography.Title>
                        <CancelMoveButtons cancelTime={cancelTime} moveTime={moveTime} ferries={moveTimes} setMoveFerry={setMoveTime} />
                    </Space>
                </Card>

                <Divider />
                <SmallTicketsTable viewTicket={(t) => setViewTicket(t)} tickets={cancelTickets} />
            </Space>

        </Drawer>
        {/* {`${JSON.stringify(viewTicket)}`} */}
        <ViewPanel
            closeIcon={<CloseSquareOutlined />}
            onClose={() => setViewTicket(null)}
            singleTicket={viewTicket}
            open={viewTicket}
            width={width > 900 ? 840 : width}
        />
    </>
}