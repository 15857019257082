import React, { useState } from "react";
import { message, Drawer, Space, Button, Form, DatePicker, Select, Input, Row } from "antd";
import moment from "moment";
import { format } from "date-fns";
import { SaveOutlined } from "@ant-design/icons";
import { getPrettyTimeAMPM } from "../../../utils/TimeUtils";
import * as NoteService from "../../../services/NoteService";

export function NewNoteDrawer(props) {
    // PROPS
    const { addNew, setAddNew, date, time } = props;

    // WIDTH
    const [width] = useState(window.innerWidth);

    // NOTES
    const [newNote, setNewNote] = useState("");
    const [newDate, setNewDate] = useState(new Date(new Date(date).setHours(12, 0, 0, 0)));
    const [newTime, setNewTime] = useState(time);
    const [newPriority, setNewPriority] = useState(0);

    async function saveNewNote() {
        if (newNote === "") {
            message.error("Please include a note");
            return;
        }
        // SEND TO SERVER
        // console.log(`note: ${newNote}, date: ${newDate}, time: ${newTime}, priorty: ${newPriority}`);

        let { error } = await NoteService.addNewNote(newNote, newPriority, newDate.getTime(), newTime);

        if (error === 0) {
            // SUCCESS
            //    // RESET
            //    // CLOSE
            message.success("Added the new note!");
            setNewNote("");
            setNewDate(new Date(date));
            setNewTime(time);
            setNewPriority(0);
            setAddNew(false);
        } else {
            // FAILED
            //    // MESSAGE
            message.error("Failed to add new note");
        }
    }
    return (
        <Drawer
            open={addNew}
            onClose={() => setAddNew(false)}
            placement="right"
            closeIcon={<></>}
            title={`Add Note for ${format(date, "do MMM yyyy")}`}
            width={width > 900 ? 600 : "100%"}
            extra={[
                <Space key="closebuttonspacer">
                    <Button onClick={() => setAddNew(false)}>Close</Button>
                </Space>
            ]}
        >
            <Form
                labelCol={{
                    span: 8
                }}
                wrapperCol={{
                    span: 16
                }}
            >
                {/* DATE */}
                <Form.Item label="Date">
                    <DatePicker
                        value={moment(newDate)}
                        onChange={(v) => setNewDate(new Date(new Date(v._d)).setHours(12, 0, 0, 0))}
                        allowClear={false}
                    />
                </Form.Item>
                {/* TIME */}
                <Form.Item label="Time">
                    <Select
                        value={newTime}
                        onChange={(value) => setNewTime(value)}
                        options={[
                            { value: -1, label: "All Day" },
                            { value: time, label: `${getPrettyTimeAMPM(time)}` }
                        ]}
                    ></Select>
                </Form.Item>
                {/* NOTE PRIORITY */}
                <Form.Item label="Priority">
                    <Select
                        value={newPriority}
                        onChange={(value) => setNewPriority(value)}
                        options={[
                            { value: 0, label: "Low" },
                            { value: 1, label: "Normal" },
                            { value: 2, label: "High" },
                            { value: 3, label: "!!! Highest" }
                        ]}
                    ></Select>
                </Form.Item>
                {/* NOTE */}
                <Form.Item label="Note">
                    <Input.TextArea
                        placeholder="Note"
                        onChange={(v) => setNewNote(v.target.value)}
                        value={newNote}
                        style={{ height: 120 }}
                    />
                </Form.Item>
            </Form>
            <Row justify={"end"}>
                <Button icon={<SaveOutlined />} type="primary" onClick={() => saveNewNote()}>
                    Save Note
                </Button>
            </Row>
        </Drawer>
    );
}
