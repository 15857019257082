// GLOBAL CONFIGURATION

// module.exports = {
//     passengers,
//     totalPassengers,
//     cars,
//     totalCars
// }

export const passengers = true;
export const totalPassengers = 96;

export const cars = false;
export const totalCars = 0;
export const deckSpaces = 0;

export const paginationLimit = 15;

export function showPrices() {
    return localStorage.getItem("showPrices") === "true" ? true : false ?? true;
}
