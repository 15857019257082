import React from "react";

import { Space, Typography, Tooltip } from "antd";

import { CarOutlined, UserOutlined } from "@ant-design/icons";

import * as PassengerUtils from "../../../utils/PassengerUtils";
import * as GlobalUtils from "../../../utils/Global";
import * as CarUtils from "../../../utils/CarUtils";

export function PassengerIconsSummary(props) {
    const { passengers, vertical, cardetails } = props;

    const totalPass =
        passengers == null
            ? 0
            : passengers.map((p) => p.quantity * p.passengers).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalCars =
        passengers == null
            ? 0
            : passengers.map((p) => p.quantity * p.deckSpaces).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
        <Space direction="vertical">
            <Space direction={vertical ? "vertical" : "horizontal"} size="middle">
                <Tooltip title={`${totalPass} Passengers`}>
                    <Space direction="horizontal" size="small">
                        <UserOutlined />
                        <Typography.Text>{totalPass}</Typography.Text>
                    </Space>
                </Tooltip>
                {GlobalUtils.cars && CarUtils.getCarTotalPassengerList(passengers) > 0 ? (
                    <Tooltip title={`${totalPass} Cars`}>
                        <Space direction="horizontal" size="small">
                            <CarOutlined />
                            <Typography.Text>{totalCars}</Typography.Text>
                        </Space>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </Space>
            {GlobalUtils.cars && CarUtils.getCarTotalPassengerList(passengers) > 0 ? (
                <Typography.Text>{`${cardetails ?? "No car details"}`}</Typography.Text>
            ) : (
                <></>
            )}
        </Space>
    );
}
