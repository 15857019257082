import React from "react";
import { Typography, Space, Button, Row, Col, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { maxDeckSpacesAvailable } from "../../../utils/Constants";

export function PassengerList(props) {
    const { newTicket, removePassenger, showRemove, showTotals } = props;
    const { passengers } = newTicket;

    return passengers.length === 0 ? (
        <Space direction="vertical" style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }}>
            <Typography.Text style={{ fontWeight: "bold" }}>No Passengers Selected For Ticket</Typography.Text>
        </Space>
    ) : (
        <Space style={{ width: "100%", paddingTop: "1em", paddingBottom: "1em" }} direction="vertical">
            {showTotals ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Row justify="space-between" style={{ width: "100%" }}>
                        <Col>
                            <Typography.Text style={{ fontWeight: "bold" }}>
                                {`Passengers: ${passengers
                                    .map((p) => p.quantity * p.passengers)
                                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)}`}
                                {maxDeckSpacesAvailable > 0
                                    ? `Cars: ${passengers
                                          .map((p) => p.quantity * p.deckSpaces)
                                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)}`
                                    : ""}
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Typography.Text style={{ fontWeight: "bold" }}>
                                {newTicket.ticket.returnTicket == true
                                    ? `Total: € ${(
                                          passengers
                                              .map((p) => p.quantity * p.price)
                                              .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 100
                                      ).toFixed(2)}`
                                    : `Total: € ${(
                                          passengers
                                              .map((p) => p.quantity * p.price)
                                              .reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 200
                                      ).toFixed(2)}`}
                            </Typography.Text>
                        </Col>
                    </Row>
                    <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.06)" }} />
                </Space>
            ) : (
                <></>
            )}
            <Space style={{ width: "100%" }} direction="vertical">
                {passengers.map((p) => (
                    <Space key={p.code} direction="vertical" style={{ width: "100%" }}>
                        <Row justify="space-between" style={{ width: "100%" }}>
                            <Col span={14}>
                                <Typography.Text key={p.code}>{`${p.quantity}x ${p.label}`}</Typography.Text>
                                {p.islander ? (
                                    <Tag size="small" style={{ marginLeft: "0.5em" }} color="green">
                                        Islander
                                    </Tag>
                                ) : (
                                    <Tag size="small" style={{ marginLeft: "0.5em" }} color="blue">
                                        Visitor
                                    </Tag>
                                )}
                                {p.code == "custom" ? (
                                    <Tag size="small" style={{ marginLeft: "0.5em" }} color="orange">
                                        Custom
                                    </Tag>
                                ) : (
                                    <></>
                                )}
                            </Col>
                            <Col span={showRemove ? 6 : 10}>
                                <Space direction="horizontal" wrap>
                                    {p.quantity * p.passengers > 20 ? (
                                        <Space>
                                            {`${p.quantity * p.passengers} x`}
                                            <UserOutlined key={p} />
                                        </Space>
                                    ) : (
                                        [...Array(p.quantity * p.passengers).keys()].map((p) => <UserOutlined key={p} />)
                                    )}
                                </Space>
                            </Col>
                            {showRemove ? (
                                <Col span={4} justify="right">
                                    <Button onClick={() => removePassenger(p.code)} key={p.code} size="small" danger>{`Remove`}</Button>
                                </Col>
                            ) : (
                                <></>
                            )}
                        </Row>
                        {showTotals ? (
                            <hr
                                style={{
                                    borderTop: "1px solid rgba(0, 0, 0, 0.06)"
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </Space>
                ))}
            </Space>
        </Space>
    );
}
