import React, { useState, useEffect } from "react";

import "./css/Dashboard.css";

import { Divider, Row, Col, PageHeader, Button, Space, Spin, Typography, Statistic, message, Switch } from "antd";

import { Link } from "react-router-dom";

import { ProfileOutlined } from "@ant-design/icons";

import DashboardTickets from "../components/dashboard/DashboardTickets";
import LogoutButton from "../components/LogoutButton";
import FerryTypeLabel from "../components/dashboard/FerryTypeLabel";

import * as GlobalUtils from "../utils/Global";

import * as TimeUtils from "../utils/TimeUtils";
import * as DashboardService from "../services/DashboardService";

import { format, isAfter, isSameDay, isToday } from "date-fns";
// import MobileDropdown from "../components/dashboard/mobile/Dropdown";
import DashboardCards from "../components/dashboard/DashboardCards";
import { DateTimeKeys } from "../components/dashboard/datetime/DateTimeKeys";
import { getTicketsForDateTime } from "../services/TicketService";
import V2FerryOutlineContainer from "../components/dashboard/FerryOutline/V2FerryOutlineContainer";
import { MemoizedNewTicketButton } from "../components/tickets/newticket/NewTicketButton";
import V2DashboardStats from "../components/dashboard/statistics/V2DashboardStats";
// import Countdown from "antd/lib/statistic/Countdown";
const { Countdown } = Statistic;

export default function Dashboard(props) {
    // const [date, setDate] = useState(new Date());
    // const [times, setTimes] = useState(null);
    // const [tickets, setTickets] = useState([]);
    // const [blocks, setBlocks] = useState([]);
    // const [selectedTime, setSelectedTime] = useState(null);
    // const [selectedType, setSelectedType] = useState(null);
    // const [selectedJourney, setSelectedJourney] = useState("dm");
    // const [update, setUpdate] = useState(0);
    // const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     loadDashboardData();
    // }, [date, selectedTime]);

    // async function loadDashboardData() {
    //     setLoading(true);
    //     let _times = await getDateFerryTimes(date);
    //     let _ticketsRes = await TicketService.getTicketsForDate(date.getTime());
    //     let _blocks = await BlockService.getBlocksForDate(date.getTime());
    //     setTimes({
    //         dtTimes: _times.dt,
    //         dmTimes: _times.dm,
    //         dt_bTimes: _times.dt_r,
    //         dm_bTimes: _times.dm_r
    //     });
    //     setBlocks(_blocks.blocks);
    //     setTickets(_ticketsRes.tickets);
    //     setUpdate(update + 1);
    //     setLoading(false);
    // }

    // async function setFerry(ferry, journey) {
    //     setSelectedTime(ferry.time);
    //     setSelectedType(ferry.type);
    //     setSelectedJourney(journey);
    // }

    // async function DashboardDateChange(date) {
    //     if (date !== null) {
    //         setDate(date._d);
    //         setSelectedTime(null);
    //     }
    // }

    // const combinedTimestamp = TimeUtils.getCombinedTimestamp(date, selectedTime);

    // const ferryBlocks = BlockUtils.getBlocksForFerry({ journey: selectedJourney, time: selectedTime, date: date }, blocks);

    const [date, setDate] = useState(new Date());
    // const [selectedTime, setSelectedTime] = useState(null);
    // const [selectedType, setSelectedType] = useState(null);
    // const [selectedJourney, setSelectedJourney] = useState("dm");
    // const [secondFerry, setSecondFerry] = useState(0); // 0 = ONLY FERRY, 1 = FIRST FERRY, 2 = SECOND FERRY
    // const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(0);
    const [combinedTwoFerry, setCombinedTwoFerry] = useState(true);

    // TIME
    const [dateTimeKey, setDateTimeKey] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function changeDateTimeKey(timeKey) {
        if (dateTimeKey === null) {
            setDateTimeKey(timeKey);
            return;
        }
        if (timeKey._id === dateTimeKey._id) {
            // RELOAD
            reloadData(timeKey);
        } else {
            setDateTimeKey(timeKey);
        }
    }

    useEffect(() => {
        loadDashboardTimeDate(dateTimeKey);
    }, [dateTimeKey]);

    async function loadDashboardTimeDate(dateTimeKey) {
        if (dateTimeKey == null) {
            return;
        }
        setLoading(true);
        let response = await getTicketsForDateTime(dateTimeKey.date, dateTimeKey.time);
        if (response.error === 0) {
            setTickets(response.data);
            // console.log(response.data);
        } else {
            console.log(response.message);
            message.error(`Error Loading Ticket Data`);
        }
        // console.log(response);
        setLoading(false);
    }

    const [timeKeyData, setTimeKeyData] = useState([]);

    useEffect(() => {
        loadTimeKeyData();
    }, [date]);

    async function loadTimeKeyData() {
        console.log("date changed");
        setIsLoading(true);
        let { data, error, message } = await DashboardService.getTimeKeyForDate(date);
        if (error === 0) {
            setTimeKeyData(data);
            // console.log(data);
            // LOAD NEAREST TIME KEY
            setNearestTimeKey(data);
            setUpdate(update + 1);
        } else {
            message.error("Failed to load timekey data");
            console.log(message);
        }
        setIsLoading(false);
    }

    async function reloadData() {
        console.log("reload");
        let { data, error, message } = await DashboardService.getTimeKeyForDate(date);
        if (error === 0) {
            setTimeKeyData(data);
            // console.log(data);
            // setUpdate(update + 1);
            // RELOAD NEAREST DATETIMEKEY
            const selectedDateTimeKey = data.filter((d) => d.type === dateTimeKey.type && d.time === dateTimeKey.time);
            setDateTimeKey(selectedDateTimeKey[0]);
            loadDashboardTimeDate(selectedDateTimeKey[0]);
        } else {
            message.error("Failed to reload data");
            console.log(message);
        }
    }

    //
    function getNextFerryTimeIndex(time, timesList) {
        let index = -1;
        for (let i = 0; i < timesList.length; i++) {
            // console.log(`time ${time}, list time: ${timesList[i]}`)
            if (time < timesList[i]) {
                index = i;
                break;
            }
        }
        return index;
    }
    //
    function setNearestTimeKey(data) {
        if (data.length === 0) {
            console.log("nearest list = 0");
            return;
        }
        const timesList = data.map((tkd) => tkd.time);

        const nearestTime = isSameDay(new Date(), date) ? parseInt(format(new Date(), "HHmm")) : 0;
        const timeIndex = getNextFerryTimeIndex(nearestTime, timesList);
        // console.log(timeIndex);
        if (timeIndex === -1) {
            setDateTimeKey(data[timesList.length - 1]);
        } else {
            setDateTimeKey(data[timeIndex]);
        }
    }

    return (
        <>
            <PageHeader ghost={false} title="Dashboard" extra={<LogoutButton key="logout" />} />

            <div style={{ margin: "0px 16px 0" }}>
                <Row align="top" justify="center" gutter={16}>
                    <Col className="gutter-row" xs={24} lg={4}>
                        <Divider orientation="left">Time Controls</Divider>

                        <Space style={{ maxWidth: "100%", width: "100%" }} direction="vertical">
                            <DateTimeKeys
                                date={date}
                                setDate={setDate}
                                dateTimeKey={dateTimeKey}
                                setDateTimeKey={(v) => changeDateTimeKey(v)}
                                timeKeyData={timeKeyData}
                                setNearestTimeKey={setNearestTimeKey}
                                isLoading={isLoading}
                                // LoadNearestFerryData={() => LoadNearestFerryData()}
                                // setTime={(v) => setTime(v)}
                                // DashboardDateChange={DashboardDateChange}
                                // setFerry={setFerry}
                                // setSelectedTime={setSelectedTime}
                            />
                        </Space>
                    </Col>

                    <Col className="gutter-row" xs={24} lg={20}>
                        {dateTimeKey == null ? (
                            <Row justify="center">
                                <Col span={2} style={{ padding: "10px 0px" }}>
                                    <Spin />
                                </Col>
                            </Row>
                        ) : (
                            <Space style={{ maxWidth: "100%", width: "100%" }} direction="vertical" size="small">
                                {/* <Typography.Text>{JSON.stringify(dateTimeKey)}</Typography.Text> */}
                                <Space direction="vertical">
                                    <Space direction="horizontal" align="baseline">
                                        <Typography.Title
                                            level={4}
                                            style={{
                                                paddingBottom: "0px",
                                                paddingTop: "1em"
                                            }}
                                        >{`Tickets for ${format(dateTimeKey.date, "do MMMM yyyy")} at ${TimeUtils.getPrettyTimeAMPM(
                                            dateTimeKey.time
                                        )}`}</Typography.Title>

                                        <FerryTypeLabel type={dateTimeKey.type} />
                                    </Space>
                                    {loading ? (
                                        <Space direction="horizontal">
                                            <Spin />
                                            <Typography.Text>Loading Tickets...</Typography.Text>
                                        </Space>
                                    ) : isAfter(
                                          new Date(),
                                          TimeUtils.getCombinedTimestamp(new Date(dateTimeKey.date), dateTimeKey.time)
                                      ) ? (
                                        <Typography.Text>{`Ferry Sailed Already`}</Typography.Text>
                                    ) : (
                                        <Space direction="horizontal" size="small">
                                            <Typography.Text>{`Time Until Ferry:`}</Typography.Text>
                                            <Countdown
                                                valueStyle={{
                                                    fontSize: "1em"
                                                }}
                                                format={
                                                    isToday(TimeUtils.getCombinedTimestamp(new Date(dateTimeKey.date), dateTimeKey.time))
                                                        ? "HH:mm:ss"
                                                        : "D [days] HH:mm:ss"
                                                }
                                                value={TimeUtils.getCombinedTimestamp(new Date(dateTimeKey.date), dateTimeKey.time)}
                                            />
                                        </Space>
                                    )}
                                    {dateTimeKey.secondFerry > 0 ? (
                                        <Space direction="horizontal">
                                            <Typography.Text>There are two ferries at this time</Typography.Text>
                                            <Switch
                                                checkedChildren="Combined"
                                                unCheckedChildren="Separate"
                                                size="small"
                                                onChange={(v) => setCombinedTwoFerry(v)}
                                                defaultChecked={combinedTwoFerry}
                                            />
                                        </Space>
                                    ) : (
                                        <></>
                                    )}
                                </Space>
                                <Divider
                                    orientation="right"
                                    style={{
                                        padding: "0px",
                                        margin: "0px"
                                    }}
                                >
                                    <MemoizedNewTicketButton reload={reloadData} />
                                </Divider>

                                <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                    <Row style={{ paddingTop: "20px" }}>
                                        <Col xs={24} md={12}>
                                            
                                            <V2DashboardStats
                                                date={dateTimeKey.date}
                                                time={dateTimeKey.time}
                                                journey={dateTimeKey.journey}
                                                combinedFerry={combinedTwoFerry}
                                                secondFerry={dateTimeKey.secondFerry}
                                                tickets={tickets}
                                            />
                                        </Col>
                                        {dateTimeKey.secondFerry && combinedTwoFerry && GlobalUtils.deckSpaces > 0 ? (
                                            <Col xs={24} md={12}>
                                                <Space direction="vertical" style={{ width: "100%" }}>
                                                    <V2FerryOutlineContainer
                                                        selectedTime={dateTimeKey.time}
                                                        secondFerry={1}
                                                        combinedTwoFerry={combinedTwoFerry}
                                                        selectedJourney={dateTimeKey.journey}
                                                        date={dateTimeKey.date}
                                                        allTickets={tickets}
                                                        currentBlocks={dateTimeKey.blocks}
                                                        reloadBlocks={reloadData}
                                                    />
                                                    <V2FerryOutlineContainer
                                                        selectedTime={dateTimeKey.time}
                                                        secondFerry={2}
                                                        combinedTwoFerry={combinedTwoFerry}
                                                        selectedJourney={dateTimeKey.journey}
                                                        date={dateTimeKey.date}
                                                        allTickets={tickets}
                                                        currentBlocks={dateTimeKey.blocks}
                                                        reloadBlocks={reloadData}
                                                    />
                                                </Space>
                                            </Col>
                                        ) : (
                                            <Col xs={24} md={12}>
                                                <V2FerryOutlineContainer
                                                    selectedTime={dateTimeKey.time}
                                                    secondFerry={dateTimeKey.secondFerry}
                                                    combinedTwoFerry={combinedTwoFerry}
                                                    selectedJourney={dateTimeKey.journey}
                                                    date={dateTimeKey.date}
                                                    allTickets={tickets}
                                                    currentBlocks={dateTimeKey.blocks}
                                                    reloadBlocks={reloadData}
                                                />
                                            </Col>
                                        )}
                                    </Row>

                                    {window.innerWidth > 900 ? (
                                        <DashboardTickets
                                            tickets={tickets}
                                            date={dateTimeKey.date}
                                            time={dateTimeKey.time}
                                            journey={dateTimeKey.journey}
                                            reload={reloadData}
                                        />
                                    ) : (
                                        <DashboardCards
                                            tickets={tickets}
                                            date={dateTimeKey.date}
                                            time={dateTimeKey.time}
                                            journey={dateTimeKey.journey}
                                            reload={reloadData}
                                        />
                                    )}
                                </Space>
                                <Divider orientation="right">
                                    <Link to="/tickets">
                                        <Button type="ghost" icon={<ProfileOutlined />}>
                                            All Tickets
                                        </Button>
                                    </Link>
                                </Divider>
                            </Space>
                        )}
                        {/* <Space style={{ maxWidth: "100%", width: "100%" }} direction="vertical" size="small">
                            {
                                <Row>
                                    <Col flex={"auto"}>
                                        <Space size="middle" style={{ width: "100%" }} align="baseline">
                                            {selectedTime ? (
                                                <Space direction="vertical">
                                                    <Typography.Title
                                                        level={4}
                                                        style={{
                                                            paddingBottom: "0px",
                                                            paddingTop: "1em"
                                                        }}
                                                    >{`Tickets for Ferry at ${Constants.getTimestamp(selectedTime)}`}</Typography.Title>
                                                    {isAfter(new Date(), combinedTimestamp) ? (
                                                        <Typography.Text>{`Ferry Sailed Already`}</Typography.Text>
                                                    ) : (
                                                        <Space direction="horizontal" size="small">
                                                            <Typography.Text>{`Time Until Ferry:`}</Typography.Text>
                                                            <Countdown
                                                                valueStyle={{
                                                                    fontSize: "1em"
                                                                }}
                                                                format={isToday(combinedTimestamp) ? "HH:mm:ss" : "D [days] HH:mm:ss"}
                                                                value={combinedTimestamp}
                                                            />
                                                        </Space>
                                                    )}
                                                </Space>
                                            ) : (
                                                <Space direction="vertical">
                                                    <Typography.Title
                                                        level={4}
                                                        style={{
                                                            paddingBottom: "0px",
                                                            paddingTop: "1em"
                                                        }}
                                                    >
                                                        {" "}
                                                        All Tickets: {format(new Date(date), "do, MMM yyy")}{" "}
                                                    </Typography.Title>
                                                </Space>
                                            )}
                                            <FerryTypeLabel type={selectedType} />
                                        </Space>
                                        <Divider
                                            orientation="right"
                                            style={{
                                                padding: "0px",
                                                margin: "0px"
                                            }}
                                        >
                                            <NewTicketButton reload={() => loadDashboardData()} />
                                        </Divider>
                                    </Col>
                                </Row>
                            }
                            {tickets === null || loading ? (
                                <Row justify="center">
                                    <Spin />
                                </Row>
                            ) : (
                                <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                    {`${selectedTime}`} 
                                    <Row style={{ paddingTop: "20px" }}>
                                        <Col xs={24} md={12}>
                                            <DashboardStats
                                                blocks={ferryBlocks}
                                                selectedTime={selectedTime}
                                                tickets={
                                                    selectedTime
                                                        ? TicketUtils.getFerryTickets(
                                                              {
                                                                  date: date,
                                                                  time: selectedTime,
                                                                  journey: selectedJourney,
                                                                  type: selectedType
                                                              },
                                                              tickets
                                                          )
                                                        : tickets
                                                }
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            {selectedTime && GlobalUtils.cars ? (
                                                <FerryOutline
                                                    blocks={ferryBlocks}
                                                    ferry={{
                                                        journey: selectedJourney,
                                                        time: selectedTime,
                                                        date: date
                                                    }}
                                                    tickets={
                                                        selectedTime
                                                            ? TicketUtils.getFerryTickets(
                                                                  {
                                                                      date: date,
                                                                      time: selectedTime,
                                                                      journey: selectedJourney,
                                                                      type: selectedType
                                                                  },
                                                                  tickets
                                                              )
                                                            : tickets
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </Col>
                                    </Row>
                                    {window.innerWidth > 900 ? (
                                        <DashboardTickets
                                            tickets={
                                                selectedTime
                                                    ? TicketUtils.getFerryTickets(
                                                          {
                                                              date: date,
                                                              time: selectedTime,
                                                              journey: selectedJourney,
                                                              type: selectedType
                                                          },
                                                          tickets
                                                      )
                                                    : tickets
                                            }
                                            date={date}
                                            time={selectedTime}
                                            journey={selectedJourney}
                                            reload={() => loadDashboardData()}
                                        />
                                    ) : (
                                        <DashboardCards
                                            tickets={
                                                selectedTime
                                                    ? TicketUtils.getFerryTickets(
                                                          {
                                                              date: date,
                                                              time: selectedTime,
                                                              journey: selectedJourney,
                                                              type: selectedType
                                                          },
                                                          tickets
                                                      )
                                                    : tickets
                                            }
                                            date={date}
                                            time={selectedTime}
                                            journey={selectedJourney}
                                            reload={() => loadDashboardData()}
                                        />
                                    )}
                                </Space>
                            )}

                            {tickets === null ? (
                                <Row justify="center">
                                    <Spin />
                                </Row>
                            ) : Constants.getTicketIssues(date, tickets).length === 0 ? (
                                <></>
                            ) : (
                                <>
                                    <Divider orientation="left">Tickets with Issues</Divider>
                                    <DashboardIssueTicketTable tickets={Constants.getTicketIssues(date, tickets)} />
                                </>
                            )}

                            <Divider orientation="right">
                                <Link to="/tickets">
                                    <Button type="ghost" icon={<ProfileOutlined />}>
                                        All Tickets
                                    </Button>
                                </Link>
                            </Divider>
                        </Space> */}
                    </Col>
                </Row>
            </div>
        </>
    );
}
