import React, { useState, useEffect } from "react";

import { PageHeader, Button, Row, Col, Space, message, Tooltip, Spin, Segmented } from "antd";

import { ControlFilled, ControlOutlined, SyncOutlined } from "@ant-design/icons";

import EditPanel from "../components/tickets/EditTicketPanel";
import ViewPanel from "../components/tickets/ViewPanel";

import * as ToryService from "../services/ToryService";
import * as TicketService from "../services/TicketService";
import LogoutButton from "../components/LogoutButton";
import TicketTable from "../components/tickets/TicketTable";
import NewTicketButton from "../components/tickets/newticket/NewTicketButton"; // MemoizedNewTicketButton
import DashboardTickets from "../components/dashboard/DashboardTickets";
import { TicketSearch } from "../components/tickets/v2tickets/TicketSearch";
import { TicketFilterStatus } from "../components/tickets/v2tickets/TicketFilterStatus";
import { TicketFilterDate } from "../components/tickets/v2tickets/TicketFilterDate";
import { TicketFilterStripePayment } from "../components/tickets/v2tickets/TicketFilterStripePayment";
import { TicketFilterMethod } from "../components/tickets/v2tickets/TicketFilterMethod";

function Tickets(props) {
    const [queryStringCheck, setQueryStringCheck] = useState(0);
    // SETTING NEW ONES
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [paginationState, setPaginationState] = useState({ current: 0, pageSize: 20, defaultPageSize: 20, total: 0 });
    const [ticketCount, setTicketCount] = useState(20);
    const [showEditDrawer, setShowEditDrawer] = useState(false);
    const [showViewDrawer, setShowViewDrawer] = useState(false);
    const [singleTicket, setSingleTicket] = useState(null);

    //
    const [ticketStatus, setTicketStatus] = useState("active");
    const [showPastTickets, setShowPastTickets] = useState(false);

    // ARCHIVE
    const [width] = useState(window.innerWidth);

    const searchOptions = {
        threshold: 0.4,
        keys: ["_id", "name", "email"]
    };

    // async function searchTickets(searchTerm) {
    //     if (searchTerm) {
    //         let response = await ToryService.searchTickets(searchTerm);
    //         setTickets(response.tickets);
    //         setTicketCount(response.tickets.length);
    //     } else {
    //         loadTickets();
    //     }
    // }

    function closeNewDrawer() {
        // setNewTicket(false);
        setSingleTicket(null);
        loadFilteredTickets();
    }

    async function editTicket(id) {
        // console.log(id);
        if (id !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(await getTicketDetails(id));
            setShowEditDrawer(true);
        }
    }

    async function viewTicket(id) {
        // console.log(id);
        if (id !== null) {
            // console.log(getTicketDetails(id));
            setSingleTicket(await getTicketDetails(id));
            setShowViewDrawer(true);
        }
    }

    async function getTicketDetails(id) {
        let ticket = null;
        // console.log(tickets);
        let response = await ToryService.getTicket(id);

        ticket = response.ticket;

        return ticket;
    }

    function closeEditDrawer() {
        setShowEditDrawer(false);
        setSingleTicket(null);
        // loadTickets();
    }
    function closeViewDrawer() {
        setShowViewDrawer(false);
        setSingleTicket(null);
        // loadTickets();
    }

    // async function loadTickets() {
    //     setLoading(true);

    //     let response = await TicketService.getAdminTickets(
    //         showPastTickets,
    //         ticketStatus,
    //         { current: 0, pageSize: 20 },
    //         {},
    //         { field: "datecreated", columnKey: "datecreated", order: "descend" }
    //     );

    //     // console.log(response);

    //     if (response.status === 200) {
    //         setTickets(response.data);
    //         setTicketCount(response.count);
    //         // message.success("Tickets Loaded");
    //     } else {
    //         setTickets([]);
    //     }
    //     setLoading(false);
    // }

    // async function tableChange(pagination, filters, sorter) {
    //     let response = await TicketService.getAdminTickets(showPastTickets, ticketStatus, pagination, sorter);

    //     if (response.status === 200) {
    //         setTickets(response.data);
    //         setTicketCount(response.count);
    //         message.success("Tickets Loaded");
    //     } else {
    //         message.error("Tickets have failed to load");
    //         setTickets([]);
    //     }
    // }
    // useEffect(() => {
    //     loadTickets();
    // }, [showPastTickets, ticketStatus]);

    function loadRequestedTicket() {
        // if(this.props.location.query.__fireba)
        // console.log(props.location.search);
        const query = new URLSearchParams(props.location.search);
        const ticket = query.get("t");
        if (ticket !== null || ticket !== undefined) {
            editTicket(ticket);
        }
    }

    useEffect(() => {
        loadRequestedTicket();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryStringCheck]);

    // FILTERS
    const [showFilters, setShowFilters] = useState(true);
    const [filterNameEmail, setFilterNameEmail] = useState("");
    const [filterStatus, setFilterStatus] = useState(["paid", "reserved"]);
    const [filterDateCreated, setFilterDateCreated] = useState(null);
    const [filterTravelDate, setFilterTravelDate] = useState(null);
    const [filterStripePayment, setFilterStripePayment] = useState("");
    const [filterMethod, setFilterMethod] = useState(["web", "app", "inperson", "admin", "kiosk", "other"]);
    const [filterShowPast, setFilterShowPast] = useState(false);

    useEffect(() => {
        if (showFilters === false) {
            setFilterStatus(["paid", "reserved"]);
            setFilterDateCreated(null);
            setFilterStripePayment("");
            setFilterMethod(["web", "app", "inperson", "admin", "kiosk", "other"]);
            setFilterShowPast(false);
        }
    }, [showFilters]);

    useEffect(() => {
        // console.log("ticket change");
        loadFilteredTickets();
        // LOAD ADVANCED TICKET
    }, [filterStatus, filterNameEmail, filterDateCreated, filterTravelDate, filterStripePayment, filterMethod, filterShowPast]);

    async function loadFilteredTickets() {
        let { data, error, count } = await TicketService.getFilteredTickets(
            filterStatus,
            filterDateCreated,
            filterTravelDate,
            filterStripePayment,
            filterMethod,
            filterShowPast,
            filterNameEmail,
            paginationState,
            { field: "datecreated", columnKey: "datecreated", order: "descend" }
        );
        if (error === 0) {
            console.log(data);
            setTicketCount(count);
            setTickets(data);
            message.success("Tickets Loaded");
        } else {
            message.error(`Failed to load tickets: ${error}`);
        }
    }

    async function tableChange(pagination, filters, sorter) {
        let response = await TicketService.getAdminTickets(showPastTickets, ticketStatus, pagination, sorter);

        // if (response.status === 200) {
        //     setTickets(response.data);
        //     setTicketCount(response.count);
        //     message.success("Tickets Loaded");
        // } else {
        //     message.error("Tickets have failed to load");
        //     setTickets([]);
        // }

        let { data, error, count } = await TicketService.getFilteredTickets(
            filterStatus,
            filterDateCreated,
            filterTravelDate,
            filterStripePayment,
            filterMethod,
            filterShowPast,
            filterNameEmail,
            pagination,
            sorter
        );
        if (error === 0) {
            console.log(data);
            setTicketCount(count);
            setTickets(data);
            message.success("Tickets Loaded");
        } else {
            message.error(`Failed to load tickets: ${error}`);
        }
    }

    return (
        <>
            <PageHeader ghost={false} title="Tickets" extra={<LogoutButton />}></PageHeader>

            <div style={{ margin: "8px 16px 0" }}>
                <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]}>
                    <Col className="gutter-row" xs={24}>
                        <Space direction="vertical" size="middle" style={{ maxWidth: "100%", width: "100%" }}>
                            <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]} justify="space-between">
                                <Col flex>
                                    <Space direction="horizontal" style={{ width: "100%" }}>
                                        <TicketSearch searchTickets={setFilterNameEmail} />
                                        <Button
                                            onClick={() => {
                                                setShowFilters(!showFilters);
                                            }}
                                            icon={showFilters ? <ControlFilled /> : <ControlOutlined />}
                                        >
                                            {`${showFilters ? "Hide Filters" : "Filters"}`}
                                        </Button>
                                    </Space>
                                </Col>
                                <Col justify="end">
                                    <Space>
                                        <Tooltip title="Reload">
                                            <Button
                                                onClick={() => loadFilteredTickets()}
                                                icon={<SyncOutlined />}
                                                loading={loading}
                                            ></Button>
                                        </Tooltip>

                                        <NewTicketButton reload={closeNewDrawer} />
                                    </Space>
                                </Col>
                            </Row>
                            {showFilters ? (
                                <Space style={{ width: "100%" }} size={"small"} direction="vertical">
                                    <Row gutter={[16, { xs: 8, sm: 16, md: 24 }]} justify="space-between">
                                        <Col span={{ sm: 24, md: 6 }} style={{ display: "block" }}>
                                            <Space style={{ width: "100%" }} size={"small"} direction="vertical">
                                                <TicketFilterStatus filterStatus={filterStatus} setFilterStatus={setFilterStatus} />
                                                <TicketFilterMethod filterMethod={filterMethod} setFilterMethod={setFilterMethod} />
                                                <Space direction="horizontal" size={"large"}>
                                                    <TicketFilterDate
                                                        label={`Date Created`}
                                                        date={filterDateCreated}
                                                        setDate={setFilterDateCreated}
                                                    />
                                                    <TicketFilterDate
                                                        label={`Travel Date`}
                                                        date={filterTravelDate}
                                                        setDate={setFilterTravelDate}
                                                    />
                                                </Space>
                                            </Space>
                                        </Col>
                                        <Col span={{ sm: 24, md: 6 }} justify="end" style={{ textAlign: "end" }}>
                                            <Space style={{ width: "100%" }} size={"small"} direction="vertical">
                                                <Segmented
                                                    onChange={(value) => setFilterShowPast(value)}
                                                    value={filterShowPast}
                                                    defaultValue={false}
                                                    options={[
                                                        {
                                                            label: "Hide Past",
                                                            value: false
                                                        },
                                                        {
                                                            label: "Show Past",
                                                            value: true
                                                        }
                                                    ]}
                                                />
                                                <TicketFilterStripePayment
                                                    filterStripePayment={filterStripePayment}
                                                    setFilterStripePayment={setFilterStripePayment}
                                                />
                                            </Space>
                                        </Col>
                                    </Row>
                                </Space>
                            ) : (
                                <></>
                            )}

                            {loading && tickets.length === 0 ? (
                                <Row justify="center">
                                    <Spin />
                                </Row>
                            ) : tickets.length === 0 ? (
                                <>
                                    <Row justify="center">No Tickets Found!</Row>
                                </>
                            ) : width > 900 ? (
                                <TicketTable
                                    tickets={tickets}
                                    ticketCount={ticketCount}
                                    viewTicket={viewTicket}
                                    editTicket={editTicket}
                                    // archiveTicket={archiveTicket}
                                    tableChange={tableChange}
                                    showDateCreated={true}
                                />
                            ) : (
                                <DashboardTickets
                                    tickets={tickets}
                                    ticketCount={ticketCount}
                                    viewTicket={viewTicket}
                                    editTicket={editTicket}
                                    // archiveTicket={archiveTicket}
                                    tableChange={tableChange}
                                    showDateCreated={true}
                                />
                            )}
                        </Space>
                    </Col>
                </Row>
            </div>

            {/* <ArchiveTickets viewArchiveTicket={(v) => viewArchiveTicket(v)} /> */}

            {singleTicket ? (
                <EditPanel
                    onClose={closeEditDrawer}
                    visible={showEditDrawer}
                    width={width > 900 ? 640 : width}
                    singleTicket={singleTicket}
                    editTicket={editTicket}
                    // archiveTicket={archiveTicket}
                    // sendReceipt={sendReceipt}
                    // sendUpdateReceipt={sendUpdateReceipt}
                />
            ) : (
                <></>
            )}

            <ViewPanel onClose={closeViewDrawer} open={showViewDrawer} width={width > 900 ? 640 : width} singleTicket={singleTicket} />
        </>
    );
}

export default Tickets;
