import React, { useEffect, useState } from "react";
// import axios from 'axios';
// CSS
import "./css/Timetables.css";

import { Divider, Tabs, PageHeader, Spin, Row, message, Typography } from "antd";

import * as ToryService from "../services/ToryService";
import * as Constants from "../utils/Constants";
import LogoutButton from "../components/LogoutButton";
import { NewTimePanel } from "../components/timetables/NewTimePanel";
import { MonthlyMasterTimetable } from "../components/timetables/MonthlyMasterTimetable";
import { EditTimePanel } from "../components/timetables/EditTimePanel";

export function Timetables(props) {
    //
    const [definitions, setDefinitions] = useState([]);
    const [times, setTimes] = useState([]);
    const [width] = useState(window.innerWidth);
    const [addNew, setAddNew] = useState(null);

    function onClose() {
        setAddNew(null);
        loadTables();
    }

    useEffect(() => {
        loadTables();
    }, []);

    function getDefinitions(defs) {
        let definitionsArray = [];
        if (defs.length === 0) {
            // console.log(defs);
            return [];
        } else {
            // console.log("here");
            for (let i = 0; i < defs.length; i++) {
                const month = defs[i].m;
                const days = defs[i].d;
                if (month.length === 1) {
                    definitionsArray.push({
                        monthLabel: Constants.months[month[0]],
                        monthDefinitions: month,
                        dayDefinitions: days
                    });
                } else {
                    // defsArray.push()
                    definitionsArray.push({
                        monthLabel: Constants.months[month[0]] + " - " + Constants.months[month[month.length - 1]],
                        monthDefinitions: month,
                        dayDefinitions: days
                    });
                }
            }
            // console.log(definitionsArray);
            return definitionsArray;
        }
    }

    async function loadTables() {
        let response = await ToryService.getPublicTimes();
        // console.log(response.master.definitions);
        // console.log(response.master.ferries);
        const _definitions = getDefinitions(response.master.definitions);
        // console.log(_definitions);
        const _times = response.master.ferries;
        // console.log(_times);
        //
        setDefinitions(_definitions);
        setTimes(_times);
    }

    async function deleteFerry(deleteid) {
        message.info("Deleting Time");
        let response = await ToryService.deleteMasterTime(deleteid);
        if (response.status === 200) {
            message.success("Deleted");
            setEditTime(null);
            loadTables();
        } else {
            message.error("There was an error");
        }
    }

    const [editTime, setEditTime] = useState(null);

    async function updateFerry(time) {
        // console.log(time);
        setEditTime(time);
    }

    return (
        <>
            <PageHeader ghost={false} title="Timetables" extra={<LogoutButton />}></PageHeader>

            <div style={{ margin: "24px 16px 0" }}>
                <Divider orientation="left">Months</Divider>
            </div>

            {definitions.length > 0 ? (
                <div style={{ margin: "24px 16px 0" }}>
                    <Tabs
                        defaultActiveKey="0"
                        tabPosition={"top"}
                        items={definitions.map((d, index) => {
                            return {
                                label: `Month: ${d.monthLabel}`,
                                key: index,
                                children: (
                                    <MonthlyMasterTimetable
                                        definitions={d}
                                        times={times}
                                        showDrawer={(v) => setAddNew(v)}
                                        loadTables={loadTables}
                                        deleteFerry={deleteFerry}
                                        updateFerry={updateFerry}
                                    />
                                )
                            };
                        })}
                    ></Tabs>
                </div>
            ) : (
                <Row justify="center">
                    <Typography.Text>{`${definitions.length}`}</Typography.Text>
                    <Spin />
                </Row>
            )}

            <NewTimePanel
                onClose={onClose}
                onSave={loadTables}
                addNew={addNew}
                visible={addNew === null ? false : true}
                width={width > 900 ? 640 : width}
                definitions={definitions}
            />

            <EditTimePanel
                width={width > 900 ? 640 : width}
                onEdit={() => console.log(editTime)}
                onClose={() => {
                    setEditTime(null);
                    loadTables();
                }}
                deleteFerry={deleteFerry}
                visible={editTime}
            />
        </>
    );
}

export default Timetables;
